import { IconType } from 'components/Icons'
import dialogPolyfill from 'dialog-polyfill'
import React, { FunctionComponent, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button, { ButtonType } from './Button'
import Icon from './Icon'

export enum ModalPosition {
	topRight = 'top-right',
	bottom = 'bottom',
}

export interface ModalProps {
	className?: string
	headerClassName?: string
	header?: string | React.ReactElement
	buttonLabel?: string
	forceAction?: boolean
	hideConfirm?: boolean
	ref?: any
	position?: ModalPosition
	onButtonClick?(): void
	onCloseClick?(): void
	onModalClose?(): void
}

export interface ModalRefActions {
	openModal(): void
	closeModal(): void
	toggleModal(): void
}

const Modal: FunctionComponent<ModalProps> = React.forwardRef<ModalRefActions, ModalProps>((props, ref: any) => {
	const { t } = useTranslation()
	/**
	 * FIXME: TypeScript 4.4.2 deprecated the HTMLDialogElement type
	 * maybe it will be reverted once the dialog element is not experimental anymore.
	 * in the meantime any is used to prevent errors
	 */
	const modal = useRef<HTMLDialogElement | any>(null)
	const modalContent = useRef<HTMLDivElement>(null)
	const [modalVisible, setModalVisibility] = useState<boolean>(false)

	useImperativeHandle(ref, () => ({
		openModal() {
			if (!modalVisible) {
				setModalVisibility(true)
			}
		},
		closeModal() {
			if (modalVisible) {
				setModalVisibility(false)
			}
		},
		toggleModal() {
			if (modalVisible) {
				setModalVisibility(false)
			} else {
				setModalVisibility(true)
			}
		},
	}))

	useEffect(() => {
		if (null !== modal.current) {
			dialogPolyfill.registerDialog(modal.current)

			if (modalVisible && !modal.current.open) {
				modal.current.showModal()

				// use scrollTop here to support Edge
				modalContent.current!.scrollTop = 0

				window.addEventListener('keydown', onEscapeHandler)
			} else if (modal.current.open) {
				modal.current!.classList.add('hide')

				modal.current.addEventListener('animationend', closeModalEvent)
			}
		}
		return () => window.removeEventListener('keydown', onEscapeHandler)
		// eslint-disable-next-line
	}, [modalVisible])

	const closeModalEvent = useCallback((event: AnimationEvent) => {
		if (event.target === modal.current) {
			modal.current!.classList.remove('hide')
			modal.current!.close()
			modal.current!.removeEventListener('animationend', closeModalEvent)

			if (props.onModalClose) {
				props.onModalClose()
			}
		}
		// eslint-disable-next-line
	}, [])

	const onButtonClick = () => {
		if (props.onButtonClick) {
			props.onButtonClick()
		}

		setModalVisibility(false)
	}

	const onCloseClick = () => {
		if (props.onCloseClick) {
			props.onCloseClick()
		}

		setModalVisibility(false)
	}

	const getClasses = (): string => {
		const classes = ['modal']

		if (props.className) {
			classes.push(props.className)
		}

		if (props.position) {
			classes.push(`modal--position-${props.position}`)
		}

		return classes.join(' ')
	}

	const onEscapeHandler = (event: KeyboardEvent) => {
		if ('Escape' === event.key) {
			event.preventDefault()

			if (props.forceAction) {
				return
			}

			setModalVisibility(false)
		}
	}

	return (
		<dialog ref={modal} className={getClasses()}>
			<div className="modal__header">
				{props.header && <div className={`modal__title ${props.headerClassName}`}>{props.header}</div>}

				{true !== props.forceAction && (
					<div className="modal__close" onClick={onCloseClick}>
						<Icon type={IconType.close} color="var(--modal-close-icon-color)" />
					</div>
				)}
			</div>

			<div ref={modalContent} className="modal__content">
				{props.children}

				{!props.hideConfirm && (
					<Button
						className="modal__button"
						type={ButtonType.primary}
						label={props.buttonLabel || t('generic.ok')}
						onClick={onButtonClick}
					/>
				)}
			</div>
		</dialog>
	)
})

Modal.defaultProps = {
	headerClassName: 'text--bold-spaced',
}

export default Modal
