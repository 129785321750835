import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'

const ResetPin = () => {
	const { t } = useTranslation()

	return (
		<div className="reset-pin center-view ">
			<h1>
				<Trans i18nKey="view.resetPin.pageTitle" />
			</h1>
			<p>
				<Trans i18nKey="view.resetPin.copyIntro" />
			</p>

			<Button type={ButtonType.primary} href={t('generic.helpHotline.telLink')}>
				<Trans i18nKey="view.resetPin.call.button.label" />
			</Button>
			<p>
				<b>
					<Trans i18nKey="view.resetPin.call.headline" />
				</b>
				<br />
				<b className="font-family-alternative font-size-m">
					<Trans i18nKey="view.resetPin.call.tel" />
				</b>
			</p>
			<p>
				<Trans i18nKey="view.resetPin.call.copy" />
			</p>

			<Button href={t('generic.helpHotline.mailLink')} type={ButtonType.primary}>
				<Trans i18nKey="view.resetPin.email.button.label" />
			</Button>
			<p>
				<b>
					<Trans i18nKey="view.resetPin.email.headline">
						<a
							className="font-size-m font-family-alternative--medium"
							href={t('generic.helpHotline.mailLink')}
						>
							{' '}
						</a>
					</Trans>
				</b>
			</p>
			<p>
				<Trans i18nKey="view.resetPin.email.copy" />
			</p>
		</div>
	)
}

export default ResetPin
