import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import i18n_de from './translations/de.json'

const resources = {
	de: {
		translation: i18n_de,
	},
}

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'de-DE',
		interpolation: {
			escapeValue: false, // react already safes from xss
		},
		react: {
			transEmptyNodeValue: '️️⚠️ MISSING TRANSLATION ⚠️', // what to return for empty Trans
			transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'li', 'ul', 'ol', 'p', 'b'], // don't convert to <1></1> if simple react elements
		},
	})

export default i18n
