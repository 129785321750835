import { usePath, useRoutes } from 'hookrouter'
import React, { useEffect } from 'react'
import routesDictionary from 'routes'
import AppUpdateModal from 'shared/components/AppUpdateModal'
import GlobalModal from 'shared/components/GlobalModal'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useAuth } from '../hooks/useAuth'
import Header from '../partials/Header'
import NotFound from './NotFound'

const RouteWrapper: React.FC = () => {
	const { routes, navigateTo } = useRouteHelper()
	const auth = useAuth()
	const routeResult = useRoutes(routes)
	const path = usePath()

	/**
	 * save the initially visited path, to make sure to redirect the user to the
	 * desired page after authentication.
	 * Authentication only runs after the first render,
	 * where the private routes are not available, yet.
	 *
	 * When the user is authenticated an effect runs and navigates to the initial path.
	 */
	if (
		undefined === auth.userData &&
		null === sessionStorage.getItem('initialPath') &&
		!path.startsWith(routesDictionary.logout.path) &&
		!path.startsWith(routesDictionary.login.path)
	) {
		sessionStorage.setItem('initialPath', path)
	}

	useEffect(() => {
		if (undefined !== auth.userData) {
			const initialPath = sessionStorage.getItem('initialPath')

			if (null !== initialPath) {
				sessionStorage.removeItem('initialPath')

				navigateTo(initialPath, true)
			}
		}
	}, [auth, navigateTo])

	// scroll to top on every page transition
	// use scrollTop here to support Edge
	useEffect(() => {
		document.documentElement.scrollTop = 0
	}, [path])

	return (
		<>
			<Header />
			<main className="main">
				{routeResult || (auth.userData ? <NotFound /> : navigateTo(routesDictionary.login.path, true))}
			</main>
			<GlobalModal />
			<AppUpdateModal />
		</>
	)
}

export default RouteWrapper
