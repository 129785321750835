import i18next from 'i18next'

export const sanitizeDecimalNumber = (maskedValue: string = '') => {
	maskedValue = i18next.language.toLowerCase().startsWith('en')
		? String(maskedValue).replace(/[^\d.]+/g, '')
		: String(maskedValue)
				.replace(/[^\d,]+/g, '')
				.replace(/,+/g, '.')

	return Number(maskedValue)
}
