import { IconType } from 'components/Icons'
import dialogPolyfill from 'dialog-polyfill'
import React, { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import useGlobalModalState from '../hooks/useGlobalModalState'
import Button, { ButtonType } from './Button'
import Icon from './Icon'
export interface GlobalModalProps {
	className?: string
	buttonLabel?: string
}

const GlobalModal: FunctionComponent<GlobalModalProps> = (props) => {
	const { t } = useTranslation()
	/**
	 * FIXME: TypeScript 4.4.2 deprecated the HTMLDialogElement type
	 * maybe it will be reverted once the dialog element is not experimental anymore.
	 * in the meantime any is used to prevent errors
	 */
	const modal = useRef<HTMLDialogElement | any>(null)
	const modalContent = useRef<HTMLDivElement>(null)
	const [modalState, modalActions] = useGlobalModalState()

	useEffect(() => {
		if (null !== modal.current) {
			dialogPolyfill.registerDialog(modal.current)

			if (modalState.open && !modal.current.open) {
				modal.current.showModal()

				window.addEventListener('keydown', onEscapeHandler)
				// use scrollTop here to support Edge
				modalContent.current!.scrollTop = 0
			} else if (!modalState.open && modal.current.open) {
				modal.current!.classList.add('hide')

				window.removeEventListener('keydown', onEscapeHandler)
				modal.current.addEventListener('animationend', closeModalEvent)
			}
		}
		// eslint-disable-next-line
	}, [modalState])

	useEffect(() => {
		let autoCloseTimeout: NodeJS.Timer

		if (undefined !== modalState.autoCloseTimeout) {
			autoCloseTimeout = setTimeout(() => {
				modalActions.closeModal()
			}, modalState.autoCloseTimeout)
		}

		return () => clearTimeout(autoCloseTimeout)
	}, [modalActions, modalState.autoCloseTimeout])

	const closeModalEvent = useCallback((event: AnimationEvent) => {
		if (event.target === modal.current) {
			modal.current!.classList.remove('hide')
			modal.current!.close()
			modal.current!.removeEventListener('animationend', closeModalEvent)
			modalActions.resetState()
		}
		// eslint-disable-next-line
	}, [])

	const onCloseClick = () => {
		modalActions.closeModal()
	}

	const getClasses = (): string => {
		const classes = ['modal']

		if (props.className) {
			classes.push(props.className)
		}

		if (modalState.className) {
			classes.push(modalState.className)
		}

		return classes.join(' ')
	}

	const onEscapeHandler = (event: KeyboardEvent) => {
		if ('Escape' === event.key) {
			event.preventDefault()

			modalActions.closeModal()
		}
	}

	return (
		<dialog ref={modal} className={getClasses()}>
			<div className="modal__header">
				{modalState.header && <div className="modal__title text--bold-spaced">{modalState.header}</div>}

				{true !== modalState.hideCloseButtons && (
					<div className="modal__close" onClick={onCloseClick}>
						<Icon type={IconType.close} color="var(--modal-close-icon-color)" />
					</div>
				)}
			</div>

			<div ref={modalContent} className="modal__content">
				{modalState.content}

				{true !== modalState.hideCloseButtons && (
					<Button
						className="modal__button"
						type={ButtonType.primary}
						label={modalState.buttonLabel || t('generic.close')}
						onClick={onCloseClick}
					/>
				)}
			</div>
		</dialog>
	)
}

export default GlobalModal
