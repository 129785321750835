import { IconType } from 'components/Icons'
import React, { ChangeEvent, forwardRef, HTMLAttributes, ReactElement, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import mergeRefs from 'react-merge-refs'
import { sanitizeDecimalNumber } from 'shared/helper/sanitizeMaskedValue'
import convertToDashCase from '../helper/convertToDashCase'
import { useUniqueId } from '../hooks/useUniqueInputId'
import Button, { ButtonType } from './Button'
import Icon, { IconSize } from './Icon'

export enum TextInputType {
	text = 'text',
	email = 'email',
	password = 'password',
	tel = 'tel',
	number = 'number',
	hidden = 'hidden',
	date = 'date',
}

export enum ValidityStateType {
	valueMissing = 'valueMissing',
	typeMismatch = 'typeMismatch',
	patternMismatch = 'patternMismatch',
	tooLong = 'tooLong',
	tooShort = 'tooShort',
	rangeUnderflow = 'rangeUnderflow',
	rangeOverflow = 'rangeOverflow',
	stepMismatch = 'stepMismatch',
	badInput = 'badInput',
	customError = 'customError',
}

export interface TextInputProps extends HTMLAttributes<HTMLInputElement> {
	type?: TextInputType
	name?: string
	value?: string | number | string[]
	label?: string | React.ReactElement
	required?: boolean
	showRequiredAsterisk?: boolean
	placeholder?: string
	className?: string
	errorMessage?: string
	error?: { type: string; message?: string }
	validityHints?: { [key in ValidityStateType]?: { type?: string; message: string } }
	hidePasswordLabel?: string
	showPasswordLabel?: string
	autoComplete?: string
	highlight?: boolean
	disabled?: boolean
	disableDelete?: boolean
	maxValue?: number
	minValue?: number
	maxLength?: number
	minLength?: number
	pattern?: string
	ref?: any
	icon?: IconType
	iconRotate?: number
	iconColor?: string
	iconSize?: IconSize
	valueFunction?: {
		name: (value: any, parameters?: any) => void
		parameters?: any
	}
	renderAsText?: boolean
	usedInForm?: boolean
	hidden?: boolean
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
	const { t } = useTranslation()
	const inputRef = useRef<HTMLInputElement | null>(null)

	const id = useUniqueId(props.type)

	const initalAttributes = () => {
		const filteredAttributes: any = { ...props }
		const propsToRemove = [
			'value',
			'className',
			'label',
			'highlight',
			'disableDelete',
			'errorMessage',
			'hidePasswordLabel',
			'showPasswordLabel',
			'error',
			'minValue',
			'maxValue',
			'icon',
			'iconRotate',
			'iconColor',
			'iconSize',
			'valueFunction',
			'renderAsText',
			'onChange',
			'validityHints',
			'usedInForm',
			'hidden',
			'showRequiredAsterisk',
		]

		for (const prop of Object.keys(filteredAttributes)) {
			if (propsToRemove.includes(prop)) {
				delete filteredAttributes[prop]
			}
		}

		if (TextInputType.number === props.type) {
			filteredAttributes.type = TextInputType.text
			filteredAttributes.inputMode = 'decimal'
			filteredAttributes.pattern = props.pattern || '[0-9,.]*'
		}

		if (undefined === props.autoComplete) {
			filteredAttributes.autoComplete = 'off'
		}

		return filteredAttributes
	}

	const [attributes, setAttributes] = useState({
		...initalAttributes(),
		value: undefined,
		className: undefined,
		label: undefined,
		highlight: undefined,
		id: props.label ? id : undefined,
		autoCapitalize: 'none',
		autoCorrect: 'off',
	})

	useEffect(() => {
		const updatesAttributes = { ...attributes }

		if (TextInputType.number === props.type) {
			updatesAttributes.type = TextInputType.text
			updatesAttributes.inputMode = 'decimal'
			updatesAttributes.pattern = props.pattern || '[0-9,.]*'
		} else {
			updatesAttributes.type = props.type
		}

		setAttributes(updatesAttributes)
		// eslint-disable-next-line
	}, [props.type])

	const handleOnChange = (e: any) => {
		if (props.maxValue && sanitizeDecimalNumber(e.target.value) > props.maxValue) {
			e.target.value = props.maxValue
		}

		if (props.minValue && sanitizeDecimalNumber(e.target.value) < props.minValue) {
			e.target.value = props.minValue
		}

		if (props.onChange) {
			props.onChange(e)
		}
	}

	const label = (): ReactElement | undefined => {
		if (!props.label) {
			return
		}

		return (
			<label className="input__label bold-small-heading" htmlFor={id}>
				{props.label}
				{props.showRequiredAsterisk && <>{props.required ? ' *' : ` ${t('generic.optionalFormField')}`}</>}
			</label>
		)
	}

	const showDelete = (): ReactElement | undefined => {
		if (props.type === TextInputType.password || true === props.disableDelete) {
			return
		}

		return (
			<div
				className="input__delete"
				onClick={() => {
					if (null !== inputRef.current) {
						inputRef.current.value = ''
						props.onChange && props.onChange({ target: inputRef.current } as ChangeEvent<HTMLInputElement>)
					}
				}}
			/>
		)
	}

	const showPassword = (): ReactElement | undefined => {
		if (props.type !== TextInputType.password) {
			return
		}

		return (
			<Button
				tabIndex={-1}
				className="input__show-password"
				type={[ButtonType.small, ButtonType.text]}
				onClick={() => {
					setAttributes({
						...attributes,
						type: TextInputType.password === attributes.type ? TextInputType.text : TextInputType.password,
					})
				}}
				label={
					TextInputType.password === attributes.type
						? props.showPasswordLabel || t('generic.showPassword')
						: props.hidePasswordLabel || t('generic.hidePassword')
				}
			/>
		)
	}

	const showError = (): ReactElement | undefined => {
		return (
			<div
				style={{ opacity: Number(undefined !== props.error) }}
				className={`input__error ${props.error ? `input__error--${props.error.type}` : ''}`}
			>
				{props.error?.message}
			</div>
		)
	}

	const showTextRepresentation = (): ReactElement | undefined => {
		if (true !== props.renderAsText) {
			return
		}

		return (
			<div className="input__text-representation">
				{props.valueFunction
					? String(props.valueFunction.name(props.value, props.valueFunction.parameters)).trim()
					: String(props.value).trim()}
			</div>
		)
	}

	const getClasses = (): string[] => {
		const classes = []

		if (props.name) {
			classes.push(`input--${convertToDashCase(props.name)}`)
		}

		if (props.className) {
			classes.push(props.className)
		}

		if (TextInputType.hidden === props.type || props.hidden) {
			classes.push('input--hidden')
		}

		if (props.highlight) {
			classes.push('input--highlighted')

			if (props.error && 'hint' === props.error.type) {
				classes.push('input--highlighted-hint')
			}
		}

		if (props.disabled) {
			classes.push('input--disabled')
		}

		if (props.value) {
			classes.push('input--dirty')
		}

		if (props.renderAsText) {
			classes.push('input--render-as-text')
		}

		return classes
	}

	return (
		<div
			className={['input', 'input--text']
				.concat(getClasses())
				.filter((item) => undefined !== item)
				.join(' ')}
		>
			{label()}
			{props.icon && (
				<Icon
					className="input__icon"
					type={props.icon}
					rotate={props.iconRotate}
					color={props.iconColor}
					size={props.iconSize}
				/>
			)}
			{showTextRepresentation()}
			<input
				{...attributes}
				defaultValue={
					true !== props.usedInForm
						? props.valueFunction
							? props.valueFunction.name(props.value, props.valueFunction.parameters)
							: props.value
						: undefined
				}
				value={
					true === props.usedInForm
						? props.valueFunction
							? props.valueFunction.name(props.value, props.valueFunction.parameters)
							: props.value
						: undefined
				}
				onChange={handleOnChange}
				disabled={props.disabled}
				className="input__tag"
				ref={mergeRefs([inputRef, ref])}
				data-type={props.type}
			/>
			{showDelete()}
			{showPassword()}
			{showError()}
		</div>
	)
})

TextInput.defaultProps = {
	type: TextInputType.text,
}

export default TextInput
