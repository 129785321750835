import { IconType } from 'components/Icons'
import React, { FunctionComponent } from 'react'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import Icon, { IconSize } from './Icon'

export enum ButtonType {
	primary = 'primary',
	secondary = 'secondary',
	small = 'small',
	text = 'text',
	round = 'round',
	link = 'link',
}

export interface ButtonProps {
	label?: string | React.ReactElement
	icon?: IconType
	iconRotate?: number
	iconColor?: string
	iconSize?: IconSize
	type?: ButtonType | ButtonType[]
	submit?: boolean
	onClick?: any
	className?: string
	disabled?: boolean
	route?: string
	hidden?: boolean
	href?: string
	ref?: React.Ref<any>
	iconRef?: React.Ref<any>
	target?: string
	tabIndex?: number
}

const Button: FunctionComponent<ButtonProps> = (props) => {
	const { navigateTo } = useRouteHelper()

	const getClassNames = (): string => {
		const classPrefix: string = 'button--'
		const classes: string[] = ['button']

		if (props.className) {
			classes.push(props.className)
		}

		if ('string' === typeof props.type) {
			classes.push(classPrefix + props.type)
		} else if ('object' === typeof props.type) {
			for (const type of props.type) {
				classes.push(classPrefix + type)
			}
		}

		return classes.join(' ')
	}

	const onClick = (e: any) => {
		if (props.route) {
			navigateTo(props.route)
		}

		if (props.onClick) {
			props.onClick(e)
		}
	}

	if (true === props.hidden) {
		return null
	}

	const renderButtonContent = () => {
		return (
			<>
				{props.icon && (
					<Icon
						type={props.icon}
						rotate={props.iconRotate}
						color={props.iconColor}
						ref={props.iconRef}
						size={props.iconSize}
					/>
				)}
				{props.label}
				{props.children}
			</>
		)
	}

	return (
		<button
			ref={props.ref}
			type={props.submit ? 'submit' : 'button'}
			className={getClassNames()}
			onClick={onClick}
			disabled={props.disabled}
			tabIndex={props.tabIndex}
		>
			{props.href && (
				<a href={props.href} target={props.target}>
					{renderButtonContent()}
				</a>
			)}

			{!props.href && <span>{renderButtonContent()}</span>}
		</button>
	)
}

Button.defaultProps = {
	type: [],
}

export default Button
