import { IconType } from 'components/Icons'
import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import Radiobutton from 'shared/components/Radiobutton'
import convertToDashCase from 'shared/helper/convertToDashCase'
import { useUniqueId } from 'shared/hooks/useUniqueInputId'
import ChevronButton from './ChevronButton'
import Icon from './Icon'

// TODO: render modal in react portal, to prevent z-index issues when polyfilled. see safari and add z-index: 0 to parent component

export interface SelectOption {
	value?: string
	label?: string | React.ReactElement
	description?: string
}

export interface SelectInputProps {
	options: SelectOption[]
	value?: string
	label?: string | React.ReactElement
	className?: string
	name?: string
	buttonColor?: string
	renderModal?: boolean
	onChange?: any
	// whether to return an obj instead of only a value
	returnEvent?: boolean
	alternativeStyle?: boolean
	disabled?: boolean
	hidden?: boolean
	required?: boolean
}

const SelectInput: FunctionComponent<SelectInputProps> = (props) => {
	const radioButtonName = useUniqueId('radiobutton')
	const { t } = useTranslation()
	const [selectedOption, setSelectedOption] = useState<string | undefined>(
		props.options.some((option) => option.value === props.value) ? props.value : props.options[0]?.value
	)

	const [preSelectedOption, setPreSelectedOption] = useState<string | undefined>(undefined)
	const modal = useRef<ModalRefActions>()

	const previousOption = () => {
		const currentOptionIndex = props.options.findIndex((option) => option.value === selectedOption)

		if (-1 === currentOptionIndex) {
			return
		}

		setSelectedOption(currentOptionIndex > 0 ? props.options[currentOptionIndex - 1].value : selectedOption)
	}

	const nextOption: any = () => {
		const currentOptionIndex = props.options.findIndex((option) => option.value === selectedOption)
		if (-1 === currentOptionIndex) {
			return
		}

		setSelectedOption(
			currentOptionIndex < props.options.length ? props.options[currentOptionIndex + 1].value : selectedOption
		)
	}

	const renderOptions = () => {
		return props.options.map((element, index) => (
			<React.Fragment key={`select-option-${index}`}>
				<option
					disabled={undefined === element.value}
					className="select-input__select-option"
					value={element.value}
				>
					{element.label ? element.label : element.value}
				</option>
			</React.Fragment>
		))
	}

	const modalInputChangedHandler = (event: React.FormEvent<HTMLSelectElement>) => {
		event.stopPropagation()

		if (event.currentTarget.name === radioButtonName) {
			setPreSelectedOption(event.currentTarget.value)
		}
	}

	const renderModalOptions = () => {
		return props.options.map((element, index) => {
			return (
				<div key={`${props.name ? `${props.name}-` : ''}${index}`} className="select-options-modal__option">
					<Radiobutton
						className="select-options-modal__radiobutton"
						onClick={modalInputChangedHandler}
						value={element.value}
						checked={element.value === preSelectedOption}
						label={element.label ? element.label : element.value}
						name={radioButtonName}
					/>
					{element.description && (
						<div className="select-options-modal__additional-content text-color-white margin--top">
							{element.description}
						</div>
					)}
				</div>
			)
		})
	}

	useEffect(() => {
		if (selectedOption !== props.value && undefined !== props.onChange && undefined !== selectedOption) {
			if (props.returnEvent) {
				props.onChange({ target: { name: props.name, value: selectedOption } })
			} else {
				props.onChange(selectedOption)
			}
		}
		setPreSelectedOption(selectedOption)
		// eslint-disable-next-line
	}, [selectedOption])

	const getClasses = () => {
		const classes = ['select-input']

		if (props.name) {
			classes.push(`input--${convertToDashCase(props.name)}`)
		}

		if (props.disabled) {
			classes.push('input--disabled')
		}

		if (props.hidden) {
			classes.push('input--hidden')
		}

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	if (!props.options || 0 === props.options.length) {
		return null
	}

	return (
		<div className={` ${getClasses()}`}>
			{props.label ? <div className="select-input__label bold-small-heading">{props.label}</div> : ''}
			{props.renderModal && (
				<Modal
					ref={modal}
					className="select-options-modal"
					onButtonClick={() => setSelectedOption(preSelectedOption)}
					onCloseClick={() => setPreSelectedOption(selectedOption)}
					header={t('view.indicesComparison.compareIndices')}
					buttonLabel={t('generic.accept')}
				>
					{renderModalOptions()}
				</Modal>
			)}

			{!props.alternativeStyle && (
				<ChevronButton
					className="select-input__button select-input__button--previous"
					disabled={0 === props.options.findIndex((option) => option.value === selectedOption)}
					color={props.buttonColor ? props.buttonColor : '#ffffff'}
					onClick={previousOption}
				/>
			)}
			<span
				className={`select-input__value ${props.renderModal ? '' : 'pointer-events--none'} ${
					!selectedOption ? 'select-input__value--nothing-selected' : ''
				}`}
				onClick={() => (modal.current ? modal.current.toggleModal() : false)}
			>
				{props.options[props.options.findIndex((option) => option.value === selectedOption)].label}
				{props.alternativeStyle && <Icon type={IconType.arrow} />}
			</span>

			<select
				className={`select-input__select-field ${props.renderModal ? 'pointer-events--none' : ''}`}
				value={selectedOption}
				disabled={props.disabled}
				onChange={(e) => {
					setSelectedOption(e.target.value)
				}}
			>
				{renderOptions()}
			</select>

			{!props.alternativeStyle && (
				<ChevronButton
					className="select-input__button select-input__button--next"
					disabled={
						props.options.length ===
						props.options.findIndex((option) => option.value === selectedOption) + 1
					}
					onClick={nextOption}
					forward={true}
					color={props.buttonColor ? props.buttonColor : '#ffffff'}
				/>
			)}
		</div>
	)
}

export default SelectInput
