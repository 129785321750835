import React from 'react'
import { Trans } from 'react-i18next'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { useBackButtonPath, useResetHeaderState } from 'state/useHeaderState'

const NotFound = () => {
	const { getMainPath } = useRouteHelper()
	useResetHeaderState()
	useBackButtonPath(getMainPath(routesDictionary.generateTan))

	return (
		<div className="not-found">
			<h1 className="page-title">
				<Trans i18nKey="view.notFound.pageTitle" />
			</h1>
		</div>
	)
}

export default NotFound
