import { useRoutes } from 'hookrouter'
import { ChallengeType, useAuth } from 'hooks/useAuth'
import React, { useEffect } from 'react'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import NotFound from 'views/NotFound'

const Login: React.FC = () => {
	const { routes, getMainPath, getChildPath, navigateTo } = useRouteHelper(routesDictionary.login)
	const routeResult = useRoutes(routes)

	const auth = useAuth()
	const challenge = auth.challenge

	useEffect(() => {
		if (undefined !== challenge && challenge.customChallengeName === challenge.previousChallengeName) {
			// console.log('do not navigate')
			return
		}

		if (undefined === challenge?.customChallengeName && undefined !== challenge?.previousChallengeName) {
			// console.log('navigate generateTan')
			// FIXME: timeout needed, see https://github.com/Paratron/hookrouter/issues/110
			setTimeout(() => navigateTo(getMainPath(routesDictionary.generateTan)), 0)
			return
		}

		switch (challenge?.customChallengeName) {
			case ChallengeType.providePassword:
				// console.log('navigate providePin')
				return navigateTo(
					getChildPath(routesDictionary.login, 'providePin'),
					false,
					'true' === challenge.initialPasswordSet ? { initialPin: true } : {}
				)

			case ChallengeType.setNewPassword:
				// console.log('navigate setNewPassword')
				return navigateTo(getChildPath(routesDictionary.login, 'setCustomPin'))

			case ChallengeType.captcha:
				return navigateTo(getChildPath(routesDictionary.login, 'captcha'))

			default:
				// console.log('navigate default')
				return navigateTo(getChildPath(routesDictionary.login, 'sendUserId'))
		}
		// eslint-disable-next-line
	}, [challenge])

	return routeResult || <NotFound />
}

export default Login
