import { IconType } from 'components/Icons'
import useApi from 'hooks/useApi'
import React, { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Form, { FormFields, FormFieldType, FormRefActions } from 'shared/components/Form'
import Icon from 'shared/components/Icon'
import Modal, { ModalRefActions } from 'shared/components/Modal'
import { TextInputType } from 'shared/components/TextInput'
import Tooltip from 'shared/components/Tooltip'
import { ApiErrorCode } from 'shared/enums'
import { FormSubmitFields } from 'shared/hooks/useForm'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { ApiError } from 'shared/interfaces'
import { useBackButtonPath, useResetHeaderState } from 'state/useHeaderState'

const ChangePin = () => {
	const { getMainPath } = useRouteHelper()

	useResetHeaderState()
	useBackButtonPath(getMainPath(routesDictionary.generateTan))
	const { t } = useTranslation()
	const api = useApi()
	const [passwordErrorMessages, setPasswordErrorMessages] = useState<string[]>()
	const successModal = useRef<ModalRefActions>()
	const changePinForm = useRef<FormRefActions>()

	const passwordInputElements: FormFields = {
		oldPassword: {
			value: '',
			required: true,
			type: TextInputType.password,
			label: t('view.changePin.formFieldLabels.currentPin'),
			autoComplete: 'current-password',
			errorMessage: t('view.changePin.formErrors.currentPin'),
			hidePasswordLabel: t('view.login.formFieldLabels.hidePin'),
			showPasswordLabel: t('view.login.formFieldLabels.showPin'),
			fieldType: FormFieldType.text,
		},
		newPassword: {
			value: '',
			required: true,
			type: TextInputType.password,
			label: t('view.changePin.formFieldLabels.newPin'),
			errorMessage: t('view.changePin.formErrors.newPin'),
			hidePasswordLabel: t('view.login.formFieldLabels.hidePin'),
			showPasswordLabel: t('view.login.formFieldLabels.showPin'),
			autoComplete: 'new-password',
			fieldType: FormFieldType.text,
		},
		confirmNewPassword: {
			value: '',
			required: true,
			type: TextInputType.password,
			label: t('view.changePin.formFieldLabels.confirmNewPin'),
			errorMessage: t('view.changePin.formErrors.confirmNewPin'),
			hidePasswordLabel: t('view.login.formFieldLabels.hidePin'),
			showPasswordLabel: t('view.login.formFieldLabels.showPin'),
			fieldType: FormFieldType.text,
		},
	}

	return (
		<div className="form-view">
			<h1 className="form-view__title">
				<Trans i18nKey="view.changePin.pageTitle" />
			</h1>
			<Form
				ref={changePinForm}
				className="form-view"
				fields={passwordInputElements}
				onSubmit={async (submittedFields: FormSubmitFields) => {
					if (submittedFields.newPassword !== submittedFields.confirmNewPassword) {
						return {
							code: ApiErrorCode.PINS_DO_NOT_MATCH,
							successful: false,
						}
					}

					const response = await api.updatePassword({
						oldPassword: submittedFields.oldPassword as string,
						newPassword: submittedFields.newPassword as string,
					})

					return response
				}}
				onError={(errorResponse: ApiError) => {
					setPasswordErrorMessages([t(`apiErrors.${errorResponse.code}`, { error: errorResponse.code })])
				}}
				errorMessages={passwordErrorMessages}
				submitLabel={t('view.changePin.buttonLabel')}
				onSuccess={() => {
					successModal.current?.openModal()
				}}
			>
				<div className="form-view__additional-fields">
					<div>
						{t('generic.passwordPolicyHelp.tooltip')}{' '}
						<Tooltip
							headline={t('generic.passwordPolicyHelp.headline')}
							content={<Trans i18nKey="generic.passwordPolicyHelp.content" />}
						/>
					</div>
				</div>
			</Form>

			<Modal
				ref={successModal}
				buttonLabel={t('generic.close')}
				onModalClose={() => {
					changePinForm.current!.resetForm()
				}}
			>
				<h1 className="padding--horizontal no-margin--top">
					<Trans i18nKey="view.changePin.successModal.modalHeadline" />
				</h1>
				<Icon type={IconType.animatedCheckmark} className="modal__success-icon" />
			</Modal>
		</div>
	)
}

export default ChangePin
