import React, { ChangeEvent, FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import Radiobutton from 'shared/components/Radiobutton'
import usePrevious from 'shared/hooks/usePrevious'

export interface SwitchSelectOption {
	value: string
	label?: string | React.ReactElement
}

export interface SwitchSelectProps {
	className?: string
	options: SwitchSelectOption[]
	value: string
	label?: string | React.ReactElement
	name?: string
	hidden?: boolean
	disabled?: boolean
	required?: boolean
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	currentIndex?: number
}

const SwitchSelect: FunctionComponent<SwitchSelectProps> = (props) => {
	const { options, currentIndex } = props
	const descriptionRefs = useRef<{ [key: number]: HTMLDivElement }>({})
	const [selectedOption, setSelectedOption] = useState<SwitchSelectOption['value'] | undefined>(props.value)
	const previousSelectedOption = usePrevious(selectedOption)

	useEffect(() => {
		if (undefined === currentIndex) {
			return
		}

		if (-1 === currentIndex) {
			setSelectedOption(undefined)
			return
		}

		setSelectedOption(options[currentIndex].value)
	}, [currentIndex, options])

	const indicatorPosition = useMemo(() => {
		let index = options.findIndex((option) => option.value === selectedOption)
		index = -1 === index ? options.findIndex((option) => option.value === previousSelectedOption) : index

		// const indicatorWidth = `${descriptionRefs.current[index]?.getBoundingClientRect().width}px`

		return {
			'--indicator-opacity': `${undefined === selectedOption ? 0 : 1}`,
			'--indicator-position': `${100 * index}%`,
			// '--indicator-width': indicatorWidth,
			'--items': options.length,
		} as React.CSSProperties
	}, [selectedOption, options, previousSelectedOption])

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (undefined !== currentIndex) {
			return
		}

		const value = event.target.value
		setSelectedOption(value)

		if (props.onChange) {
			props.onChange(event)
		}
	}

	const renderOptions: () => JSX.Element[] = () => {
		return options.map((element, index) => {
			return (
				<div
					key={`switch-select-${index}`}
					className="switch-select__radiobutton"
					ref={(instance: HTMLDivElement) => {
						descriptionRefs.current[index] = instance
					}}
				>
					<Radiobutton
						index={index}
						value={element.value}
						label={element.label}
						checked={element.value === selectedOption}
						onChange={handleOnChange}
						name={`${props.name ? props.name : 'radiobutton-switch'}`}
					/>
				</div>
			)
		})
	}

	const renderLabel: () => JSX.Element | void = () => {
		if (props.label) {
			return <div className="switch-select__label">{props.label}</div>
		}
	}

	const getClasses = () => {
		const classes = ['switch-select']

		if (props.disabled) {
			classes.push('input--disabled')
		}

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	return (
		<div className={getClasses()} style={indicatorPosition}>
			{renderLabel()}
			<div className="switch-select__bar">{renderOptions()}</div>
		</div>
	)
}

export default SwitchSelect
