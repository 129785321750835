import { ReactComponent as AnimatedCheckmarkIcon } from 'assets/animated-checkmark.svg'
import { ReactComponent as AnimatedRadiobuttonIcon } from 'assets/animated-radiobutton.svg'
import { ReactComponent as ArrowIcon } from 'assets/arrow.svg'
import { ReactComponent as IconCalendar } from 'assets/icons/calendar.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/icon-close.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/icon-help.svg'
import { ReactComponent as InfoIcon } from 'assets/icons/icon-info.svg'
import { ReactComponent as LockedIcon } from 'assets/icons/locked.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg'

export enum IconType {
	animatedCheckmark = 'animatedCheckmark',
	animatedRadiobutton = 'animatedRadiobutton',
	arrow = 'arrow',
	close = 'close',
	help = 'help',
	info = 'info',
	locked = 'locked',
	logout = 'logout',
	calendar = 'calendar',
}

export const IconAssignment = {
	[IconType.animatedCheckmark]: AnimatedCheckmarkIcon,
	[IconType.animatedRadiobutton]: AnimatedRadiobuttonIcon,
	[IconType.arrow]: ArrowIcon,
	[IconType.close]: CloseIcon,
	[IconType.help]: HelpIcon,
	[IconType.info]: InfoIcon,
	[IconType.locked]: LockedIcon,
	[IconType.logout]: LogoutIcon,
	[IconType.calendar]: IconCalendar,
}
