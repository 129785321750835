import React from 'react'
import globalHook, { Store } from 'use-global-hook'

export interface GlobalModalState {
	open: boolean
	content: React.ReactElement | undefined
	header?: React.ReactElement | string | undefined
	buttonLabel?: React.ReactElement | string | undefined
	className?: string | undefined
	autoCloseTimeout?: number
	hideCloseButtons?: boolean
	onCloseAction?: () => void
}

export interface GlobalModalActions {
	openModal: () => void
	closeModal: () => void
	toggleModal: () => void
	setContent: (content: React.ReactElement | undefined) => void
	setHeader: (header: React.ReactElement | string | undefined) => void
	setClass: (className: string | undefined) => void
	setAutoCloseTimeout: (autoCloseTimeout: number) => void
	setHideCloseButtons: () => void
	resetState: () => void
	updateState: () => void
	setButtonLabel: (header: React.ReactElement | string | undefined) => void
	setOnCloseAction: (cb: () => void) => void
}

const setButtonLabel = (
	store: Store<GlobalModalState, GlobalModalActions>,
	buttonLabel: string | React.ReactElement | undefined
) => {
	store.setState({ ...store.state, buttonLabel })
}

const setClass = (store: Store<GlobalModalState, GlobalModalActions>, className: string | undefined) => {
	store.setState({ ...store.state, className })
}

const openModal = (store: Store<GlobalModalState, GlobalModalActions>) => {
	store.setState({ ...store.state, open: true })
}

const closeModal = (store: Store<GlobalModalState, GlobalModalActions>) => {
	store.setState({ ...store.state, open: false })

	if (store.state.onCloseAction) {
		store.state.onCloseAction()
	}
}

const toggleModal = (store: Store<GlobalModalState, GlobalModalActions>) => {
	if (store.state.open) {
		store.actions.closeModal()
	} else {
		store.actions.openModal()
	}
}

const setContent = (store: Store<GlobalModalState, GlobalModalActions>, content: React.ReactElement | undefined) => {
	store.setState({ ...store.state, content })
}

const setHeader = (
	store: Store<GlobalModalState, GlobalModalActions>,
	header: React.ReactElement | string | undefined
) => {
	store.setState({ ...store.state, header })
}

const setAutoCloseTimeout = (store: Store<GlobalModalState, GlobalModalActions>, autoCloseTimeout: number) => {
	store.setState({ ...store.state, autoCloseTimeout })
}

const setHideCloseButtons = (store: Store<GlobalModalState, GlobalModalActions>) => {
	store.setState({ ...store.state, hideCloseButtons: true })
}

const resetState = (store: Store<GlobalModalState, GlobalModalActions>) => {
	store.setState(initialState)
}

const updateState = (store: Store<GlobalModalState, GlobalModalActions>) => {
	store.setState({ ...store.state })
}

const setOnCloseAction = (store: Store<GlobalModalState, GlobalModalActions>, onCloseAction: () => void) => {
	store.setState({ ...store.state, onCloseAction })
}

const initialState: GlobalModalState = {
	open: false,
	header: undefined,
	content: undefined,
	buttonLabel: undefined,
	className: undefined,
	autoCloseTimeout: undefined,
	hideCloseButtons: undefined,
	onCloseAction: undefined,
}

const actions = {
	openModal,
	closeModal,
	setClass,
	toggleModal,
	setContent,
	setHeader,
	resetState,
	updateState,
	setButtonLabel,
	setAutoCloseTimeout,
	setHideCloseButtons,
	setOnCloseAction,
}

const useGlobalModalState = globalHook<GlobalModalState, GlobalModalActions>(React, initialState, actions)

// FIXME: use-global-hook 0.3 removed "react" as a property from globalHook, but the types are not updated yet
// @ts-ignore
// const useGlobalModalState = globalHook<RGlobalModalState, GlobalModalActions>(initialState, actions)

export default useGlobalModalState
