import useAsyncEffect from '@n1ru4l/use-async-effect'
import { IconType } from 'components/Icons'
import useApi from 'hooks/useApi'
import React, { useEffect, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Button, { ButtonType } from 'shared/components/Button'
import Countdown from 'shared/components/Countdown'
import { TanData } from 'shared/interfaces'
import { useClipboard } from 'use-clipboard-copy'

const openerUrl = process.env.REACT_APP_BAV_APP_URL

const GenerateTan = () => {
	const api = useApi()
	const clipboard = useClipboard({
		onSuccess() {
			setCopied(true)
		},
	})

	const { t } = useTranslation()

	const [tan, setTan] = useState<TanData | undefined>()
	const [copied, setCopied] = useState<boolean>(false)

	const previousTan = useRef<number | string | undefined>('\u2007')
	const checkmarkIconRef = useRef<HTMLDivElement>(null)

	const onMessageReceivedFromApp = (event: MessageEvent) => {
		if (openerUrl !== event.origin) {
			return
		}

		// console.log('received message', event.data)
	}

	useEffect(() => {
		if (null !== window.opener) {
			window.opener.postMessage({ event: 'init' }, openerUrl)
		}

		window.addEventListener('message', onMessageReceivedFromApp)
		return () => window.removeEventListener('message', onMessageReceivedFromApp)
	}, [])

	/**
	 * send message to opener
	 */
	useEffect(() => {
		if (null === window.opener) {
			return
		}

		checkmarkIconRef.current?.addEventListener(
			'animationend',
			() => {
				window.opener.postMessage({ event: 'closeTanWindow' }, openerUrl)
			},
			{ once: true }
		)
	}, [copied])

	useAsyncEffect(
		function*(setErrorHandler: any, c: any) {
			if (tan !== undefined) {
				return
			}

			const tanData = yield* c(api.getTan())

			if (tanData) {
				setTan(tanData)
			}
		},
		[tan]
	)

	const onCountdownFinished = () => {
		previousTan.current = tan?.token
		setTan(undefined)
		setCopied(false)
	}

	const handleClick = () => {
		if (undefined !== tan?.token) {
			clipboard.copy(tan.token)

			if (null !== window.opener) {
				window.opener.postMessage({ tan: tan.token, event: 'tanSent' }, openerUrl)
			} else {
				// console.log('no opener window found')
			}
		}
	}

	return (
		<div className="generate-tan">
			<div className="generate-tan__title">
				<h1>
					<Trans i18nKey="view.generateTan.pageTitle" />
				</h1>
			</div>

			<div className="font-family-alternative--medium text-color-cyan font-size-xl">
				<span
					className={`generate-tan__current-tan ${
						tan?.token === undefined ? 'generate-tan__current-tan--hide' : ''
					}`}
				>
					{tan?.token || previousTan.current}
				</span>
				<Countdown
					className="generate-tan__countdown"
					time={tan?.validSeconds || 0}
					running={tan?.token !== undefined}
					onFinish={onCountdownFinished}
				/>
			</div>

			<Button
				iconRef={checkmarkIconRef}
				className={`generate-tan__button ${copied ? 'generate-tan__button--active' : ''}`}
				onClick={handleClick}
				label={t(copied ? 'view.generateTan.tanCopied' : 'view.generateTan.copyTan')}
				type={ButtonType.primary}
				icon={copied ? IconType.animatedCheckmark : undefined}
			/>
		</div>
	)
}

export default GenerateTan
