import React, { FunctionComponent, useEffect, useState } from 'react'

export enum countdownDirection {
	left = 'left',
	right = 'right',
}

export interface CountdownProps {
	running: boolean
	time: number
	color?: string
	direction?: countdownDirection
	className?: string
	onFinish?(): void
	onStart?(): void
}

let timeout: NodeJS.Timeout

const Countdown: FunctionComponent<CountdownProps> = props => {
	const [running, setRunning] = useState(false)

	const getClasses = () => {
		const classes = ['countdown']

		if (props.className) {
			classes.push(props.className)
		}

		return classes.join(' ')
	}

	const onFinish = () => {
		if (props.onFinish) {
			props.onFinish()
		}

		setRunning(false)
	}

	const onStart = () => {
		if (props.onStart) {
			props.onStart()
		}
	}

	const getStyles = () => {
		return {
			'--transform-origin': `${props.direction ? props.direction : 'right'}`,
			'--countdown-progress': `${running ? 0 : 1}`,
			'--transition-time': `${running ? props.time : 0}s`,
		} as React.CSSProperties
	}

	useEffect(() => {
		if (props.running) {
			setRunning(true)
		}

		if (!running) {
			return
		}

		onStart()

		timeout = setTimeout(() => {
			onFinish()
		}, props.time * 1000)
		return () => clearTimeout(timeout)
		// eslint-disable-next-line
	}, [props.running, running])

	return (
		<div style={getStyles()} className={getClasses()}>
			<div className="countdown__progress" />
		</div>
	)
}

export default Countdown
