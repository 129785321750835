import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import parseDate from 'shared/helper/parseDate'
import { TextInputProps, TextInputType } from '../components/TextInput'

export enum FormErrorType {
	none = 'none',
	fieldInvalid = 'field-invalid',
	errorResponse = 'error-response',
	unchanged = 'unchanged',
	allFieldsEmpty = 'all-fields-empty',
}

export interface FormInput {
	value: string | number | string[]
	required?: boolean
	type?: string
}

export interface FormSubmitFields {
	[key: string]: string | number | undefined
}

export const useForm = (
	initialValues: { [key: string]: TextInputProps },
	onSubmit: (submittedFields: FormSubmitFields) => any,
	onError: () => any,
	onlySubmitOnChangedValues: boolean = false,
	hideSubmitIfFieldsEmpty: boolean = false
) => {
	const { t } = useTranslation()

	const [inputs, setInputs] = useState(initialValues)
	const initialInputValues = useRef(initialValues)
	const currentInput = useRef<HTMLInputElement | null>(null)
	const [formError, setFormError] = useState<FormErrorType | undefined>()
	const formResetted = useRef<boolean>(false)

	const validateCurrentInputField = (field: TextInputProps): { type: string; message?: string } | undefined => {
		if (null === currentInput.current) {
			return undefined
		}

		let fieldIsValid = false
		let error = {
			type: 'error',
			message: field.errorMessage || t(`generic.formErrors.${field.type}`),
		}

		if (currentInput.current.checkValidity) {
			fieldIsValid = currentInput.current.checkValidity()
		}

		if (false === fieldIsValid && undefined !== field.validityHints) {
			for (const [type, hint] of Object.entries(field.validityHints)) {
				/**
				 * typescript does not allow accessing validity keys via dynamic variable.
				 * therefore the ts-ignore comment is required
				 */
				// @ts-ignore
				if (true === currentInput.current.validity[type]) {
					error = { ...error, ...hint }
				}
			}
		}

		if (((field.required && !fieldIsValid) || !fieldIsValid) && undefined === field.error) {
			return error
		}

		if (fieldIsValid && undefined !== field.error) {
			return undefined
		}
	}

	useEffect(() => {
		const validateForm = (): void => {
			if (FormErrorType.errorResponse === formError) {
				return
			}

			const invalid = Object.values(inputs).some(
				(input) =>
					(undefined !== input.error && !input.disabled) ||
					(input.required && !input.disabled && !input.value)
			)

			const unchanged = Object.keys(inputs).every(
				(key) => inputs[key].value === initialInputValues.current[key].value
			)

			const allInputsEmpty = Object.keys(inputs).every((key) => '' === String(inputs[key].value).trim())

			let updatedFormError = FormErrorType.none

			if (invalid) {
				updatedFormError = FormErrorType.fieldInvalid
			}

			if (onlySubmitOnChangedValues && unchanged) {
				updatedFormError = FormErrorType.unchanged
			}

			if (hideSubmitIfFieldsEmpty && allInputsEmpty) {
				updatedFormError = FormErrorType.allFieldsEmpty
			}

			setFormError(updatedFormError)
		}

		validateForm()
		/**
		 * FIXME:
		 * initialValues cannot be included here as it breaks the form validation by
		 * resetting the formError
		 *
		 * to reproduce:
		 * add initialValues to dependency array
		 * enter wrong pin in tan-service app on login
		 */
		// eslint-disable-next-line
	}, [inputs, onlySubmitOnChangedValues, formError])

	const handleSubmit = (event?: React.MouseEvent) => {
		if (event) {
			event.preventDefault()
		}

		if (undefined !== formError && false === [FormErrorType.none, FormErrorType.unchanged].includes(formError)) {
			return onError()
		}

		const submittedFields = Object.keys(inputs).reduce((fields: FormSubmitFields, fieldKey: string) => {
			let initialValue: string | number | undefined
			let value: string | number | undefined

			if (true === inputs[fieldKey].disabled) {
				return fields
			}

			switch (inputs[fieldKey].type) {
				case TextInputType.date:
					initialValue =
						undefined === initialInputValues.current[fieldKey].value
							? undefined
							: parseDate(initialInputValues.current[fieldKey].value)
					value = undefined === inputs[fieldKey].value ? undefined : parseDate(inputs[fieldKey].value)
					break

				case TextInputType.number:
					initialValue =
						undefined === initialInputValues.current[fieldKey].value
							? undefined
							: Number(initialInputValues.current[fieldKey].value)
					/**
					 * return undefined if value is '' or undefined to prevent returning NaN or a false-positive 0
					 */
					value =
						'' === inputs[fieldKey].value || undefined === inputs[fieldKey].value
							? undefined
							: Number(inputs[fieldKey].value)
					break

				default:
					initialValue =
						undefined === initialInputValues.current[fieldKey].value
							? undefined
							: String(initialInputValues.current[fieldKey].value).trim()
					value = undefined === inputs[fieldKey].value ? undefined : String(inputs[fieldKey].value).trim()
			}

			if (true === onlySubmitOnChangedValues && initialValue === value) {
				return fields
			}

			fields[fieldKey] = value

			return fields
		}, {})

		if (0 === Object.keys(submittedFields).length) {
			return
		}

		onSubmit(submittedFields)
	}

	const handleFormError = (error: FormErrorType) => {
		setFormError(error)
	}

	const handleInputChange = (
		event: React.ChangeEvent<HTMLInputElement>,
		previouslyUpdatedInputs?: { [key: string]: TextInputProps }
	) => {
		if (event.persist) {
			event.persist()
		}

		let updatedValue: string | string[] = event.target.value

		// FIXME: checkbox behaviour is weird... needs validation as well
		if ('checkbox' === event.target.type) {
			// if ( event.target.type ===  )
			const value = event.target.value
			const currentValue = (inputs[event.target.name].value as string[]) || []

			updatedValue = event.target.checked
				? currentValue.concat([value])
				: currentValue.filter((itemValue: string) => itemValue !== value)
		}

		let updatedInputs = { ...(previouslyUpdatedInputs || inputs) }

		updatedInputs[event.target.name] = {
			...updatedInputs[event.target.name],
			value: updatedValue,
			error: validateCurrentInputField(updatedInputs[event.target.name]),
		}

		// check needed to prevent error if event was created by hand to update value from outside
		if (event.target instanceof HTMLInputElement) {
			currentInput.current = event.target
		}

		setInputs(updatedInputs)

		setFormError(FormErrorType.none)

		return updatedInputs
	}

	const handleFormReset = () => {
		formResetted.current = true
		setInputs(initialValues)
		setFormError(undefined)
	}

	return {
		handleFormError,
		handleSubmit,
		handleInputChange,
		handleFormReset,
		inputs,
		formError,
	}
}
