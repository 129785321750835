import React, { useEffect } from 'react'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import globalHook, { Store } from 'use-global-hook'

// Defining your own state and associated actions is required
export interface HeaderState {
	backButtonPath: string | undefined
	navigationTitle: string | React.ReactElement | undefined
}

// Associated actions are what's expected to be returned from globalHook
export interface HeaderActions {
	setBackButtonPath: (backButtonPath: HeaderState['backButtonPath']) => void
	setNavigationTitle: (navigationTitle: HeaderState['navigationTitle']) => void
	resetState: () => void
}

const setBackButtonPath = (store: Store<HeaderState, HeaderActions>, backButtonPath: HeaderState['backButtonPath']) => {
	store.setState({ ...store.state, backButtonPath })
}
const setNavigationTitle = (
	store: Store<HeaderState, HeaderActions>,
	navigationTitle: HeaderState['navigationTitle']
) => {
	store.setState({ ...store.state, navigationTitle })
}
const resetState = (store: Store<HeaderState, HeaderActions>) => {
	store.setState(initialState)
}

const initialState: HeaderState = {
	backButtonPath: undefined,
	navigationTitle: undefined,
}

const actions = {
	setBackButtonPath,
	setNavigationTitle,
	resetState,
}

const useHeaderState = globalHook<HeaderState, HeaderActions>(React, initialState, actions)

export default useHeaderState

export const useBackButtonPath = (path?: HeaderState['backButtonPath']) => {
	const { getParentPath } = useRouteHelper()

	const [, setValue] = useHeaderState<HeaderActions['setBackButtonPath']>(
		undefined,
		(headerActions: HeaderActions) => headerActions.setBackButtonPath
	)

	useEffect(() => {
		setValue(path || getParentPath())
		// eslint-disable-next-line
	}, [])
}

export const useNavigationTitle = (title: HeaderState['navigationTitle']) => {
	const [, setValue] = useHeaderState<HeaderActions['setNavigationTitle']>(
		undefined,
		(headerActions: HeaderActions) => headerActions.setNavigationTitle
	)

	useEffect(() => {
		setValue(title)
		// eslint-disable-next-line
	}, [])
}

export const useResetHeaderState = () => {
	const [, reset] = useHeaderState<HeaderActions['resetState']>(
		undefined,
		(headerActions: HeaderActions) => headerActions.resetState
	)

	useEffect(() => {
		reset()
		// eslint-disable-next-line
	}, [])
}
