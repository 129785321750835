import { IconType } from 'components/Icons'
import React, { FunctionComponent, ReactElement, useMemo, useState } from 'react'
import { OnChangeDateCallback } from 'react-calendar'
import DatePicker, { DatePickerProps } from 'react-date-picker/dist/entry.nostyle'
import { useTranslation } from 'react-i18next'
import convertToDashCase from '../helper/convertToDashCase'
import { useUniqueId } from '../hooks/useUniqueInputId'
import Icon from './Icon'

export enum ValidityStateType {
	valueMissing = 'valueMissing',
	typeMismatch = 'typeMismatch',
	patternMismatch = 'patternMismatch',
	tooLong = 'tooLong',
	tooShort = 'tooShort',
	rangeUnderflow = 'rangeUnderflow',
	rangeOverflow = 'rangeOverflow',
	stepMismatch = 'stepMismatch',
	badInput = 'badInput',
	customError = 'customError',
}

export interface DateInputProps extends DatePickerProps {
	label?: string | React.ReactElement
	error?: { type: string; message?: string }
	ref?: any
	showRequiredAsterisk?: boolean
}

export const DateInput: FunctionComponent<DateInputProps> = React.forwardRef((props: DateInputProps, ref: any) => {
	const id = useUniqueId('date')
	const { t } = useTranslation()

	const { onChange, className, error, label, name, value, showRequiredAsterisk, ...attributes } = props

	const [selectedDate, setSelectedDate] = useState<Date | Date[] | null>(value || null)
	const [startDate, setStartDate] = useState<Date | undefined>()

	const handleOnChange: OnChangeDateCallback = (value, event) => {
		setSelectedDate(value)

		if (onChange) {
			;(onChange as OnChangeDateCallback)(value, event)
		}
	}

	const inputLabel = useMemo((): ReactElement | undefined => {
		if (!label) {
			return
		}

		return (
			<label className="date-input__label bold-small-heading" htmlFor={id}>
				{label}
				{showRequiredAsterisk && <>{props.required ? ' *' : ` ${t('generic.optionalFormField')}`}</>}
			</label>
		)
	}, [id, label, props.required, showRequiredAsterisk, t])

	const showError = (): ReactElement | undefined => {
		return (
			<div
				style={{ opacity: Number(undefined !== error) }}
				className={`date-input__error ${error ? `date-input__error--${error.type}` : ''}`}
			>
				{error?.message}
			</div>
		)
	}

	const classes = useMemo((): string[] => {
		const classes = []

		if (name) {
			classes.push(`date-input--${convertToDashCase(name)}`)
		}

		if (className) {
			classes.push(className)
		}

		if (selectedDate) {
			classes.push('date-input--dirty')
		}

		return classes.flat()
	}, [className, name, selectedDate])

	return (
		<div
			className={['date-input']
				.concat(classes)
				.filter((item) => undefined !== item)
				.join(' ')}
		>
			{inputLabel}

			<DatePicker
				inputRef={ref}
				className="date-input__tag"
				showLeadingZeros={true}
				onChange={handleOnChange}
				value={selectedDate}
				calendarIcon={<Icon type={IconType.calendar} color="var(--color-gold)" />}
				clearIcon={<></>}
				minDetail="month"
				openCalendarOnFocus={false}
				activeStartDate={startDate}
				onActiveStartDateChange={({ activeStartDate }) => {
					setStartDate(activeStartDate)
				}}
				onCalendarOpen={() => {
					if (null === selectedDate) {
						setStartDate(new Date())
					}
				}}
				{...attributes}
			/>

			{showError()}
		</div>
	)
})
