import { API, Auth } from 'aws-amplify'
import routesDictionary from 'routes'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import { ApiData, ApiError, TanData } from 'shared/interfaces'

const useApi = () => {
	const { getMainPath, navigateTo } = useRouteHelper()

	const apiDataObject = async (dataObject?: ApiData) => {
		/**
		 * if the user has been logged out on another device or the access token has
		 * been revoked, then auto-logout the current device
		 */
		Auth.currentUserInfo().then((userObject) => {
			if (
				null === userObject ||
				(null !== userObject && Object.entries(userObject).length === 0 && userObject.constructor === Object)
			) {
				return navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true })
			}
		})

		const currentUser = await Auth.currentSession()

		const data = {
			...dataObject,
			headers: {
				Authorization: `Bearer ${currentUser.getIdToken().getJwtToken()}`,
			},
		}

		return data
	}

	const getTan = async (): Promise<TanData | null> => {
		const data = await apiDataObject()

		const tanData = await API.get('authApi', 'token', data)
			.then((response: TanData) => {
				return response
			})
			.catch((err) => {
				console.log(err)
				return null
			})

		return tanData

		// const randomnumber = (minimum: number, maximum: number) =>
		// 	Math.floor(Math.random() * (maximum - minimum + 1)) + minimum

		// return delay(1000, randomnumber(100000, 999999))
	}

	const updatePassword = async ({
		oldPassword,
		newPassword,
	}: {
		oldPassword: string
		newPassword: string
	}): Promise<boolean | ApiError> => {
		const data = await apiDataObject({ body: { oldPassword, newPassword } })

		const responseData = await API.put('authApi', 'update', data)
			.then((response: { data: true }) => {
				return response.data
			})
			.catch((err: { response: ApiError }) => {
				return err.response
			})

		return responseData
	}

	return {
		getTan,
		updatePassword,
	}
}

export default useApi
