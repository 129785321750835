import { Challenge, useAuth } from 'hooks/useAuth'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import Tooltip from 'shared/components/Tooltip'
import { ApiErrorCode } from 'shared/enums'
import isDev from 'shared/helper/isDev'

const SetCustomPin = () => {
	const { t } = useTranslation()
	const auth = useAuth()
	const [errorMessages, setErrorMessages] = useState<string[]>()

	const inputElements: FormFields = {
		newPassword: {
			value: isDev() ? (process.env.REACT_APP_PIN as string) : '',
			required: true,
			type: TextInputType.password,
			label: t('view.login.formFieldLabels.setCustomIdPin'),
			hidePasswordLabel: t('view.login.formFieldLabels.hidePin'),
			showPasswordLabel: t('view.login.formFieldLabels.showPin'),
			autoComplete: 'new-password',
			fieldType: FormFieldType.text,
		},
		confirmNewPassword: {
			value: isDev() ? (process.env.REACT_APP_PIN as string) : '',
			required: true,
			type: TextInputType.password,
			label: t('view.login.formFieldLabels.confirmCustomIdPin'),
			hidePasswordLabel: t('view.login.formFieldLabels.hidePin'),
			showPasswordLabel: t('view.login.formFieldLabels.showPin'),
			fieldType: FormFieldType.text,
		},
	}

	return (
		<div className="form-view">
			<div className="form-view__title">
				<h1>
					<Trans i18nKey="view.login.pageTitle.setCustomPin" />
				</h1>

				<Trans i18nKey="view.login.bodytext.setCustomPin" />
			</div>
			<Form
				className="form-view"
				fields={inputElements}
				onSubmit={(submittedFields: FormFields) => {
					if (submittedFields.newPassword !== submittedFields.confirmNewPassword) {
						return {
							lastChallengeError: ApiErrorCode.PINS_DO_NOT_MATCH,
							successful: false,
						}
					}

					return auth.sendChallengeAnswer(submittedFields)
				}}
				onError={(errorResponse: Challenge) => {
					setErrorMessages([
						t(`apiErrors.${errorResponse.lastChallengeError}`, { error: errorResponse.lastChallengeError }),
					])
				}}
				errorMessages={errorMessages}
				submitLabel={t('view.login.buttonLabel.setCustomPin')}
			>
				<div className="form-view__additional-fields">
					<div>
						{t('generic.passwordPolicyHelp.tooltip')}{' '}
						<Tooltip
							headline={t('generic.passwordPolicyHelp.headline')}
							content={<Trans i18nKey="generic.passwordPolicyHelp.content" />}
						/>
					</div>
				</div>
			</Form>
		</div>
	)
}

export default SetCustomPin
