import { IconType } from 'components/Icons'
import { HookRouter, navigate, usePath } from 'hookrouter'
import { useAuth } from 'hooks/useAuth'
import { I18nFormatModule } from 'i18next'
import React, { useMemo } from 'react'
import routesDictionary from 'routes'
import { UserGroup } from 'shared/enums'

export interface RouteItem {
	path: string
	view: React.ReactElement
	showInNavigation?: boolean | (() => boolean)
	navigationName?: I18nFormatModule | string
	authRequired?: boolean
	hideIfLoggedIn?: boolean
	hideIfLoggedOut?: boolean
	icon?: IconType
	children?: { [key: string]: RouteItem }
	parameters?: string[]
	parameterValues?: () => { [key: string]: string | number | undefined }
	queryParams?: { [key: string]: string | boolean | number }
	exactPath?: string[]
	blockStatus?: UserGroup[]
	allowStatus?: UserGroup[]
}
export function useRouteHelper(parentRoute?: RouteItem) {
	const { userData } = useAuth()
	const authenticated = useMemo(() => !!userData, [userData])
	const userGroups = useMemo(() => userData?.['cognito:groups'], [userData])
	const currentPath = usePath(false)
	const dictionary = useMemo(() => parentRoute?.children || routesDictionary, [parentRoute])

	const getParentPath = () => {
		const parentPath = window.location.pathname.split('/')
		parentPath.pop()

		return parentPath.join('/')
	}

	const redirectTo = (
		fromUrl?: string,
		toUrl?: string,
		queryParams?: {
			[key: string]: string | number | boolean
		},
		replace?: boolean
	) => {
		if (undefined === fromUrl || undefined === toUrl) {
			return
		}

		if (currentPath === fromUrl) {
			navigateTo(toUrl, replace, queryParams)
		}
	}

	const navigateTo = (
		path?: string,
		replace?: boolean,
		queryParams?: {
			[key: string]: string | number | boolean | undefined
		},
		replaceQueryParams?: boolean
	) => {
		if (undefined === path) {
			return
		}

		return navigate(path, replace, queryParams, replaceQueryParams)
	}

	const getMainPath = (route: RouteItem): string | undefined => {
		// return undefined if any of the userGroups is added to blockStatus of this route
		if (
			userGroups?.length &&
			route.blockStatus &&
			true === userGroups.some((status) => route.blockStatus!.includes(status))
		) {
			return undefined
		}

		// return undefined if not any of the userGroups is contained in allowStatus of this route
		if (
			userGroups?.length &&
			route.allowStatus &&
			false === userGroups.some((status) => route.allowStatus!.includes(status))
		) {
			return undefined
		}

		return route.path
	}

	const getChildPath = (
		route: RouteItem,
		child: string,
		parameters?: { main?: string[]; child?: string[] }
	): string | undefined => {
		if (undefined === route.children) {
			return undefined
		}

		const parentPath = getMainPath(route)
		let childPath = getMainPath(route.children[child])

		if (undefined === parentPath || undefined === childPath) {
			return
		}

		// remove leading "/" from childPath
		childPath = childPath.substring(1)

		return [parentPath, parameters?.main, childPath, parameters?.child].filter(Boolean).join('/')
	}

	/**
	 * returns only the items from routesDictionary that do not need authenticaton
	 * when the user is not logged in,
	 * or all items if the user is authenticated
	 *
	 * these items get also filtered by blockStatus and allowStatus,
	 * depending on the current user status
	 *
	 */
	const filteredRoutesDictionary: RouteItem[] = Object.values(dictionary)
		.filter((item: RouteItem) => {
			return (
				authenticated ||
				(!authenticated && (false === item.authRequired || false === parentRoute?.authRequired))
			)
		})
		.filter((item: RouteItem) => {
			return item.blockStatus && userGroups?.length
				? false === userGroups.some((status) => item.blockStatus!.includes(status))
				: true
		})
		.filter((item: RouteItem) => {
			return item.allowStatus && userGroups?.length
				? true === userGroups.some((status) => item.allowStatus!.includes(status))
				: true
		})

	/**
	 * returns the RouteObject for HookRouter
	 *
	 */
	const routes: HookRouter.RouteObject = filteredRoutesDictionary.reduce(
		(result: HookRouter.RouteObject, item: RouteItem) => {
			let key: string

			if (item.parameters) {
				key = [item.path, ...item.parameters].join('/:')

				if (item.children) {
					key += '*'
				}
			} else if (item.children) {
				key = `${item.path}*`
			} else {
				key = item.path
			}

			result[key] = (params: any) => React.cloneElement(item.view, params)

			return result
		},
		{}
	)

	return {
		getParentPath,
		navigateTo,
		redirectTo,
		getMainPath,
		getChildPath,
		filteredRoutesDictionary,
		routes,
	}
}
