// Thanks to https://usehooks.com/useAuth/

import { Auth } from 'aws-amplify'
import { usePath } from 'hookrouter'
import Cookies from 'js-cookie'
import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import routesDictionary from 'routes'
import { ApiErrorCode, UserGroup } from 'shared/enums'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'
import usePageVisibility from 'use-page-visibility'

let refreshTokenTimeout: NodeJS.Timeout
let autoLogoutTimeout: NodeJS.Timeout

// TODO: refactor AuthContext class with proper types
export class AuthContext {
	public userData?: UserData
	public challenge?: Challenge
	public sendUserId?: any
	public sendChallengeAnswer?: any
	public signout?: any

	constructor(userData: UserData, challenge: Challenge, sendUserId: any, sendChallengeAnswer: any, signout: any) {
		this.userData = userData
		this.challenge = challenge
		this.sendUserId = sendUserId
		this.sendChallengeAnswer = sendChallengeAnswer
		this.signout = signout
	}
}

export interface UserData {
	email: string
	firstName: string
	lastName: string
	phoneNumber: string
	iat: number
	'cognito:groups': UserGroup[]
	'cognito:username': string
}

export enum ChallengeType {
	providePassword = 'PASSWORD_INITIATE_AUTH',
	setNewPassword = 'PASSWORD_RESET_REQUIRED',
	setEmailAddress = 'EMAIL_REQUIRED',
	provideMfa = 'MFA_VERIFIER',
	captcha = 'CAPTCHA_REQUIRED',
}

export interface Challenge {
	customChallengeName?: ChallengeType
	lastChallengeError?: ApiErrorCode
	initialPasswordSet?: string
	remainingTries?: string
	previousChallengeName?: ChallengeType
	successful?: boolean
}

const authContext = createContext<AuthContext>({})

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: any) {
	const auth: any = useProvideAuth()
	return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
	return useContext(authContext)
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
	const userData = useRef<UserData | undefined>()
	const tokenIssuedAt = useRef<{ iat: number; diffToLocalTime: number }>()
	const user = useRef()
	const [challenge, setChallenge] = useState<Challenge | undefined>()
	const path = usePath()
	// just used to force a re-render
	const [, setUserInitialized] = useState<boolean>(false)
	const { getMainPath, navigateTo } = useRouteHelper()

	const sendUserId = async ({ username }: { username: string }) => {
		try {
			const currentUser = await Auth.signIn(username.toLowerCase().trim())
			user.current = currentUser

			setChallenge(currentUser.challengeParam)

			return true
		} catch (e) {
			console.error('error', e)
			return false
		}
	}

	const updateTokenIssuedAt = (currentUserData: UserData) => {
		const { iat } = currentUserData
		const diffToLocalTime = iat * 1000 - new Date().getTime()

		tokenIssuedAt.current = {
			iat: iat * 1000,
			diffToLocalTime,
		}
	}

	const sendChallengeAnswer = async (answer: { [key: string]: string }): Promise<boolean | Challenge> => {
		const value = Object.values(answer)[0]

		try {
			const currentUser = await Auth.sendCustomChallengeAnswer(user.current, value)
			const previousChallengeName = challenge?.customChallengeName

			user.current = currentUser

			if (currentUser.challengeParam?.customChallengeName === previousChallengeName) {
				return {
					...currentUser.challengeParam,
					successful: false,
				}
			}

			const updatedChallenge = {
				...currentUser.challengeParam,
				previousChallengeName,
			}

			setChallenge(updatedChallenge)

			return true
		} catch (e) {
			console.error('error', e)
			return false
		}
	}

	const signout = async () => {
		try {
			await Auth.signOut({ global: true })
			// } catch (e) {
			// console.log('signout error', e)
		} finally {
			Object.keys(Cookies.get()).forEach((cookieName) => {
				/**
				 * clear cookies from domain with an without leading dot,
				 * to make sure they are deleted in all browsers
				 */
				const neededAttributes = {
					path: '/',
					domain: `.${process.env.REACT_APP_COOKIE_DOMAIN}`,
				}
				Cookies.remove(cookieName, neededAttributes)

				neededAttributes.domain = String(process.env.REACT_APP_COOKIE_DOMAIN)
				Cookies.remove(cookieName, neededAttributes)
			})

			userData.current = undefined
			setChallenge(undefined)
			setUserInitialized(false)
		}
	}

	const checkUserAuthentication = (bypassCache: boolean = false) => {
		Auth.currentAuthenticatedUser({
			bypassCache, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
		})
			.then((currentUser) => {
				const userNotInitialized = undefined === userData.current

				const updatedUserData = currentUser.signInUserSession.idToken.payload

				updateTokenIssuedAt(updatedUserData)
				userData.current = updatedUserData

				/**
				 * start the autoLogoutTimeout after every tokenRefresh
				 */
				if (true === bypassCache) {
					clearTimeout(autoLogoutTimeout)
					const autoLogoutTime = Number(process.env.REACT_APP_AUTO_LOGOUT_IN_MINUTES) * 60 * 1000

					autoLogoutTimeout = setTimeout(() => {
						navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true })
					}, autoLogoutTime)
				}

				if (true === userNotInitialized) {
					setUserInitialized(true)
				}
			})
			.catch((err) => {
				// console.log(err)
				if (undefined !== userData.current) {
					navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true })
				}
			})

		/**
		 * HINT:
		 * currently amplify does not return an error if the access token has
		 * been revoked, e.g. by logging out on another device
		 *
		 * as a workaround the currentUserInfo is checked.if it returns an
		 * empty object, the access token has been revoked
		 */
		Auth.currentUserInfo().then((userObject) => {
			if (null !== userObject && Object.entries(userObject).length === 0 && userObject.constructor === Object) {
				navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true })
			}
		})
	}

	/**
	 * this effect only runs after changing the page or making an api request
	 */
	useEffect(() => {
		if (undefined === tokenIssuedAt.current || undefined === userData.current) {
			return
		}

		clearTimeout(refreshTokenTimeout)

		const tokenAge = new Date().getTime() + tokenIssuedAt.current.diffToLocalTime - tokenIssuedAt.current.iat
		const maxTokenAge = Number(process.env.REACT_APP_AUTO_LOGOUT_IN_MINUTES) * 60 * 1000
		const remainingTokenValidity = maxTokenAge - tokenAge
		const autoRefreshTime = Number(process.env.REACT_APP_AUTO_TOKEN_REFRESH_IN_SECONDS) * 1000

		if (remainingTokenValidity <= 0) {
			navigateTo(getMainPath(routesDictionary.logout), false, { autoLogout: true })
			return
		}

		/**
		 * if the current tokens' validity is less than the autoRefreshTimeout,
		 * the token has to be refreshed immediately, to prevent it from being invalidated
		 *
		 * otherwise start a timer to refresh the token automatically after
		 * n seconds (autoRefreshTimeout).
		 * this only happens if the user does not interact with the app for the time
		 * of the autoRefreshTimeout
		 *
		 * as this effect only runs after changing the page or making an api request,
		 * maximum valid user sessing is maxTokenAge + autoRefreshTimeout
		 */
		if (autoRefreshTime > remainingTokenValidity) {
			checkUserAuthentication(true)
		} else {
			refreshTokenTimeout = setTimeout(() => {
				checkUserAuthentication(true)
			}, autoRefreshTime)
		}

		return () => clearTimeout(refreshTokenTimeout)

		// eslint-disable-next-line
	}, [path])

	/**
	 * 1. this effect makes sure that the current user is loaded again when reloading the page
	 * 2. it clears the autoLogoutTimeout when the view is destroyed
	 * 3. it runs checkUserAuthentication with bypassCache set to false when challenge updates
	 *    this way userData is set when the user is logged in successfully
	 * 4. it runs checkUserAuthentication with bypassCache set to false when page becomes
	 *    visible again, to check if user was logged out
	 */
	useEffect(() => {
		checkUserAuthentication(false)

		return () => clearTimeout(autoLogoutTimeout)
		// eslint-disable-next-line
	}, [challenge])

	usePageVisibility(() => checkUserAuthentication(false))

	// Return the user object and auth methods
	return {
		userData: userData.current,
		challenge,
		sendUserId,
		sendChallengeAnswer,
		signout,
	}
}
