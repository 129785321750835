import { IconType } from 'components/Icons'
import React, { FunctionComponent, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import useGlobalModalState from 'shared/hooks/useGlobalModalState'
import Icon from './Icon'

export interface TooltipProps {
	header?: string | ReactElement
	headline?: string | HTMLDivElement
	content?: string | HTMLDivElement | ReactElement
	closeButtonLabel?: string | ReactElement
	icon?: IconType
	color?: string
}

const Tooltip: FunctionComponent<TooltipProps> = (props) => {
	const { t } = useTranslation()
	const [, modalActions] = useGlobalModalState()

	return (
		<button
			className="tooltip"
			onClick={(e) => {
				e.stopPropagation()

				modalActions.setHeader(props.header ?? t('generic.help'))
				modalActions.setContent(
					<div className="padding--horizontal margin--vertical">
						<h2 className="margin--vertical font-family-alternative--medium fluid no-margin--top">
							{props.headline}
						</h2>
						<hr className="hr hr--narrow margin--vertical" />
						<div>{props.content}</div>
					</div>
				)
				modalActions.setButtonLabel(props.closeButtonLabel)
				modalActions.setClass('modal--tooltip')
				modalActions.openModal()
			}}
			type="button"
		>
			<Icon
				type={props.icon ? props.icon : IconType.help}
				color={props.color ? props.color : 'var(--tooltip-icon-color)'}
			/>
		</button>
	)
}

export default Tooltip
