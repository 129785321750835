import { Trans } from 'react-i18next'
import { RouteItem } from 'shared/hooks/useRouteHelper'
import AccountLocked from 'views/AccountLocked'
import ChangePin from 'views/ChangePin'
import Imprint from 'views/Imprint'
import ConfirmCaptcha from 'views/Login/ConfirmCaptcha'
import Login from 'views/Login/Login'
import ProvidePin from 'views/Login/ProvidePin'
import SendUserId from 'views/Login/SendUserId'
import SetCustomPin from 'views/Login/SetCustomPin'
import Logout from 'views/Logout'
import ResetPin from 'views/ResetPin'
import GenerateTan from './views/GenerateTan'

const routesDictionary: { [key: string]: RouteItem } = {
	generateTan: {
		path: '/',
		view: <GenerateTan />,
		navigationName: <Trans i18nKey="view.generateTan.navigationName" />,
		showInNavigation: true,
	},
	changePin: {
		path: '/change-pin',
		view: <ChangePin />,
		navigationName: <Trans i18nKey="view.changePin.navigationName" />,
		showInNavigation: true,
	},
	login: {
		path: '/login',
		view: <Login />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.login.navigationName" />,
		hideIfLoggedIn: true,
		hideIfLoggedOut: false,
		authRequired: false,
		children: {
			sendUserId: {
				path: '/provide-id',
				view: <SendUserId />,
			},
			providePin: {
				path: '/provide-pin',
				view: <ProvidePin />,
			},
			captcha: {
				path: '/captcha',
				view: <ConfirmCaptcha />,
			},
			setCustomPin: {
				path: '/set-custom-pin',
				view: <SetCustomPin />,
			},
		},
	},
	logout: {
		path: '/logout',
		view: <Logout />,
		showInNavigation: true,
		navigationName: <Trans i18nKey="view.logout.navigationName" />,
		hideIfLoggedIn: false,
		hideIfLoggedOut: true,
		authRequired: false,
	},
	accountLocked: {
		path: '/account-locked',
		view: <AccountLocked />,
		authRequired: false,
		hideIfLoggedIn: true,
	},
	resetPin: {
		path: '/reset-pin',
		view: <ResetPin />,
		authRequired: false,
		hideIfLoggedIn: true,
	},
	imprint: {
		path: '/imprint',
		view: <Imprint />,
		showInNavigation: true,
		authRequired: false,
		navigationName: <Trans i18nKey="view.imprint.navigationName" />,
		hideIfLoggedIn: false,
	},
}

export default routesDictionary
