import { IconType } from 'components/Icons'
import React, { FunctionComponent, ReactElement, useState } from 'react'
import { useUniqueId } from '../hooks/useUniqueInputId'
import Icon from './Icon'

export interface RadiobuttonProps {
	index?: number
	name?: string
	value?: string | number
	label?: string | React.ReactElement
	required?: boolean
	className?: string
	onClick?: any
	onChange?: any
	checkOnClick?: boolean
	error?: string
	checked?: boolean
	disabled?: boolean
	hidden?: boolean
}

const Radiobutton: FunctionComponent<RadiobuttonProps> = props => {
	const id = useUniqueId('radiobutton')
	const [attributes] = useState({
		...props,
		value: undefined,
		className: undefined,
		label: undefined,
		checked: undefined,
		defaultChecked: props.checked,
		id: props.label ? id : undefined,
	})

	const label = (): ReactElement | undefined => {
		if (!props.label) {
			return
		}

		return (
			<label className="input__label" htmlFor={id}>
				{props.label}
			</label>
		)
	}

	const pseudoLabel = (): ReactElement => {
		return (
			<label className="input__pseudo-radiobutton" htmlFor={id}>
				<Icon type={IconType.animatedRadiobutton} className="radiobutton__svg" />
			</label>
		)
	}

	const getClasses = (): string => {
		const classes = ['radiobutton']

		if (props.className) {
			classes.push(props.className)
		}

		if (true === props.checked) {
			classes.push('radiobutton--checked')
		}

		return classes.join(' ')
	}

	return (
		<label className={getClasses()} onClick={props.onClick}>
			<input {...attributes} type="radio" value={props.value} className="input__tag" />
			{pseudoLabel()}
			{label()}
		</label>
	)
}

export default Radiobutton
