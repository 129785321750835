import React from 'react'
import { Trans } from 'react-i18next'
import Captcha from 'shared/components/Captcha'

const ConfirmCaptcha = () => {
	return (
		<div className="form-view">
			<div className="form-view__title">
				<h1>
					<Trans i18nKey="view.login.pageTitle.captcha" />
				</h1>
				<p>
					<Trans i18nKey="view.login.bodytext.captcha" />
				</p>
			</div>
			<div className="form-view__additional-content">
				<Captcha />
			</div>
		</div>
	)
}

export default ConfirmCaptcha
