import useAsyncEffect from '@n1ru4l/use-async-effect'
import AlreadyLoggedIn from 'components/AlreadyLoggedIn'
import { useQueryParams } from 'hookrouter'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import { useAuth } from '../../hooks/useAuth'
import { TextInputType } from '../../shared/components/TextInput'
import isDev from '../../shared/helper/isDev'

const SendUserId = () => {
	const [queryParams] = useQueryParams()

	const { t } = useTranslation()
	const auth = useAuth()
	const [authTries, setAuthTries] = useState<number>(0)

	const inputElements: FormFields = {
		username: {
			value: isDev() ? queryParams.id || (process.env.REACT_APP_USER_ID as string) : queryParams.id || '',
			required: true,
			type: TextInputType.text,
			label: t('generic.id'),
			autoComplete: 'username',
			fieldType: FormFieldType.text,
		},
	}

	useAsyncEffect(
		function*(setErrorHandler: any, c: any) {
			if (undefined === queryParams.id) {
				return
			}

			const loginSuccessful = yield* c(auth.sendUserId({ username: queryParams.id }))

			if (false === loginSuccessful) {
				setAuthTries(1)
			}
		},
		[queryParams]
	)

	if (auth.userData) {
		return <AlreadyLoggedIn />
	}

	if (queryParams.id) {
		if (0 === authTries) {
			return null
		}
	}

	return (
		<div className="form-view">
			<div className="form-view__title">
				<h1>
					{(queryParams.name && (
						<Trans
							i18nKey="view.login.pageTitle.sendUserIdAlternative"
							values={{ name: queryParams.name }}
						/>
					)) || <Trans i18nKey="view.login.pageTitle.sendUserId" />}
				</h1>
			</div>
			<Form
				className="form-view"
				fields={inputElements}
				onSubmit={auth.sendUserId}
				submitLabel={t('view.login.buttonLabel.sendUserId')}
			/>
		</div>
	)
}

export default SendUserId
