import { IconType } from 'components/Icons'
import React, { FunctionComponent, ReactElement, useState } from 'react'
import { useUniqueId } from '../hooks/useUniqueInputId'
import Icon from './Icon'

export interface CheckboxProps {
	name?: string
	value?: string | number
	label?: string | React.ReactElement
	required?: boolean
	className?: string
	onChange?: any
	error?: string
	checked?: boolean
	disabled?: boolean
}

const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
	const id = useUniqueId('checkbox')
	const [attributes] = useState({
		...props,
		value: undefined,
		className: undefined,
		label: undefined,
		checked: undefined,
		name: props.name ? props.name : undefined,
		defaultChecked: props.checked,
		id: props.label ? id : undefined,
	})

	const label = (): ReactElement | undefined => {
		if (!props.label) {
			return
		}

		return (
			<label className="input__label" htmlFor={id}>
				{props.label}
			</label>
		)
	}

	const pseudoLabel = (): ReactElement => {
		return (
			<label className="input__pseudo-checkbox" htmlFor={id}>
				<Icon type={IconType.animatedCheckmark} className="checkbox__svg" />
			</label>
		)
	}

	const showError = (): ReactElement | undefined => {
		return (
			<span style={{ opacity: Number(undefined !== props.error) }} className="input__error">
				{props.error}
			</span>
		)
	}

	const getClasses = (): string[] => {
		const classes = []

		if (props.className) {
			classes.push(props.className)
		}

		return classes
	}

	return (
		<div
			className={['input', 'input--checkbox', 'checkbox']
				.concat(getClasses())
				.filter((item) => undefined !== item)
				.join(' ')}
		>
			<input {...attributes} type="checkbox" value={props.value} className="input__tag" />
			{pseudoLabel()}
			{label()}
			{showError()}
		</div>
	)
}

export default Checkbox
