import React from 'react'
import Navigation from './Navigation'
import NavigationTitle from './NavigationTitle'

const Header: React.FC = () => {
	return (
		<header className="header">
			{/* <BackButton /> */}
			<NavigationTitle />
			<Navigation />
		</header>
	)
}

export default Header
