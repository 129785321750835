import React from 'react'
import { ProvideAuth } from '../hooks/useAuth'
import RouteWrapper from './RouteWrapper'

const App: React.FC = () => {
	return (
		<ProvideAuth>
			<RouteWrapper />
		</ProvideAuth>
	)
}

export default App
