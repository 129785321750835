import { IconAssignment, IconType } from 'components/Icons'
import React, { FunctionComponent } from 'react'

export enum IconSize {
	extraSmall = 'extra-small',
	small = 'small',
	medium = 'medium',
	large = 'large',
	extraLarge = 'extra-large',
	max = 'max',
}

interface IconProps {
	type: IconType
	rotate?: number
	className?: string
	color?: string
	width?: string | number
	ref?: any
	size?: IconSize
}

const Icon: FunctionComponent<IconProps> = React.forwardRef((props, ref: any) => {
	const getClassNames = (): string => {
		const classes: string[] = ['icon']

		if (props.className) {
			classes.push(props.className)
		}

		if (props.size) {
			classes.push(`icon-size--${props.size}`)
		}

		return classes.join(' ')
	}

	const getStyles = (): React.CSSProperties | undefined => {
		const styles: any = {}

		styles.overflow = 'visible'

		if (props.rotate) {
			styles.transform = `rotate(${props.rotate}deg)`
		}

		if (props.width) {
			styles.width = `${props.width}px`
		}

		if (props.color) {
			styles['--svg-color'] = `${props.color}`
		}

		return styles as React.CSSProperties
	}

	const IconTag = IconAssignment[props.type]

	return <IconTag ref={ref} className={getClassNames()} style={getStyles()} />
})

export default Icon
