import { IconType } from 'components/Icons'
import React, {
	ChangeEvent,
	forwardRef,
	FunctionComponent,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { FormErrorType, FormSubmitFields, useForm } from 'shared/hooks/useForm'
import Button, { ButtonType } from './Button'
import Captcha from './Captcha'
import Checkbox, { CheckboxProps } from './Checkbox'
import { DateInput, DateInputProps } from './DateInput'
// import Checkbox, { CheckboxProps } from './Checkbox'
import ErrorMessage from './ErrorMessage'
import { GooglePlacesAutocomplete, IGooglePlacesAutocomplete } from './GooglePlacesAutocomplete'
import Radiobutton, { RadiobuttonProps } from './Radiobutton'
import SelectInput, { SelectInputProps } from './SelectInput'
import SwitchSelect, { SwitchSelectProps } from './SwitchSelect'
import { TextAreaProps } from './Textarea'
import TextInput, { TextInputProps, TextInputType } from './TextInput'

export interface FormProps {
	fields: FormFields
	onSubmit: any
	onError?: any
	onSuccess?: any
	submitLabel?: string
	errorMessages?: (string | React.ReactElement)[]
	errorIcon?: IconType
	className?: string
	formLocked?: boolean
	formUnlockable?: boolean
	onFormUnlock?: () => Promise<boolean>
	unlockFormLabel?: string
	updatedValues?: { [key: string]: any }
	unlockFormType?: ButtonType | ButtonType[]
	onFormChange?: (fields: FormFields) => void
	hidden?: boolean
	disabled?: boolean
	onlySubmitOnChangedValues?: boolean
	hideSubmitIfFieldsEmpty?: boolean
	hideSubmit?: boolean
	useCaptcha?: boolean
	ref?: any
}

/**
 * FIXME: REFACTOR
 * use generics for proper typing
 */
export type FormField = (
	| (TextInputProps & { fieldType: FormFieldType.text })
	| (SelectInputProps & { fieldType: FormFieldType.select })
	| (SwitchSelectProps & { fieldType: FormFieldType.switchSelect })
	| (TextAreaProps & { fieldType: FormFieldType.textArea })
	| ({
			name?: string
			label?: string | React.ReactElement
			value: string
			options: RadiobuttonProps[]
			hidden?: boolean
			disabled?: boolean
			required?: boolean
			className?: string
	  } & {
			fieldType: FormFieldType.radioButtons
	  })
	| (CheckboxProps & { fieldType: FormFieldType.checkBox })
	| (IGooglePlacesAutocomplete & { fieldType: FormFieldType.googlePlacesAutoComplete })
	| (DateInputProps & { fieldType: FormFieldType.date })
) & { error?: { type: string; message?: string } }
// | ({ value?: string | number | undefined | string[]; label?: string; options: CheckboxProps[] } & {
// 		fieldType: FormFieldType.checkBoxes
//   })

export interface FormFields {
	[key: string]: FormField
}

export enum FormFieldType {
	select = 'select',
	switchSelect = 'switchSelect',
	text = 'text',
	googlePlacesAutoComplete = 'googlePlacesAutoComplete',
	textArea = 'textArea',
	radioButtons = 'radioButtons',
	checkBoxes = 'checkBoxes',
	checkBox = 'checkBox',
	date = 'date',
}

export interface FormRefActions {
	resetForm(): void
	handleFormError(): void
	submitForm(): void
}

const Form: FunctionComponent<FormProps> = forwardRef<FormRefActions, FormProps>((props, ref: any) => {
	const { t } = useTranslation()
	const [formLocked, setFormLocked] = useState(props.formLocked)
	const form = useRef<HTMLFormElement>(null)
	const requestInProgress = useRef<boolean>(false)

	useImperativeHandle(ref, () => ({
		resetForm() {
			handleFormReset()
		},
		handleFormError() {
			handleFormError(FormErrorType.errorResponse)
		},
		submitForm() {
			onHandleSubmit()
		},
	}))

	useEffect(() => {
		if (props.formLocked !== formLocked) {
			setFormLocked(props.formLocked)
		}
	}, [formLocked, props.formLocked])

	const getPrefixedClassName = (
		partial: string,
		includeBaseClass?: boolean,
		addErrorClasses?: boolean
	): string | undefined | undefined => {
		const classes: string[] = includeBaseClass ? [partial] : []

		if (props.className) {
			classes.push(`${props.className}__${partial}`)
		}

		if (props.formLocked) {
			classes.push(`${props.className}__${partial}--${formLocked ? 'locked' : 'unlocked'}`)
		}

		if (props.hidden) {
			classes.push('hidden')
		}

		if (addErrorClasses && undefined !== formError) {
			classes.push(`${props.className}__${partial}--error-${formError}`)
		}

		return classes.join(' ')
	}

	const onSubmit = async (submittedFields: FormSubmitFields) => {
		/**
		 * prevent multiple form submits
		 */
		if (true === requestInProgress.current) {
			return
		}

		requestInProgress.current = true

		const submit = await props.onSubmit(submittedFields)

		if (
			undefined === submit ||
			false === submit ||
			false === submit.successful ||
			(undefined !== submit.status && submit.status >= 400)
		) {
			onError(submit?.data || submit)
			return handleFormError(FormErrorType.errorResponse)
		}

		onSuccess()
	}

	const onSuccess = () => {
		requestInProgress.current = false

		if (props.formLocked) {
			setFormLocked(true)
		}

		if (undefined === props.onSuccess) {
			return
		}

		props.onSuccess()
	}

	const onError = (submitResponse?: any) => {
		requestInProgress.current = false

		if (undefined === props.onError) {
			return
		}

		props.onError(submitResponse)
	}

	const onChange = (event: ChangeEvent<HTMLInputElement>) => {
		// set original field value as well to handle the value outside form component
		props.fields[event.target.name].value = event.target.value
		const updatedFields = handleInputChange(event) as FormFields

		if (props.onFormChange) {
			props.onFormChange(updatedFields)
		}
	}

	// const onCheckboxChange = (event: ChangeEvent<HTMLInputElement>, key: string) => {
	// 	// FIXME: ohne die folgenden anweisungen werden die daten in useForm nicht korrekt gespeichert
	// 	const value = event.target.value
	// 	const currentValue = (props.fields[key].value as string[]) || []

	// 	props.fields[key].value = event.target.checked
	// 		? currentValue.concat([value])
	// 		: currentValue.filter((itemValue: string) => itemValue !== value)

	// 	handleInputChange(event)
	// }
	const { inputs, formError, handleInputChange, handleSubmit, handleFormError, handleFormReset } = useForm(
		{
			...props.fields,
			...(props.useCaptcha
				? {
						captchaResponseToken: {
							type: TextInputType.hidden,
							value: undefined,
							required: true,
						},
				  }
				: {}),
		},
		onSubmit,
		onError,
		props.onlySubmitOnChangedValues,
		props.hideSubmitIfFieldsEmpty
	)

	/**
	 * creates a dummy event to change field values from outside
	 */
	useEffect(() => {
		if (undefined === props.updatedValues) {
			return
		}
		let updatedFields: { [key: string]: TextInputProps } = { ...inputs }

		for (const [key, value] of Object.entries(props.updatedValues)) {
			const updateEvent = {
				target: {
					name: key,
					value,
				},
			}
			updatedFields = handleInputChange(updateEvent as React.ChangeEvent<HTMLInputElement>, updatedFields)
		}

		if (props.onFormChange) {
			props.onFormChange(updatedFields as FormFields)
		}
		// eslint-disable-next-line
	}, [props.updatedValues])

	const renderInput = (key: string, input: FormField, index: number): JSX.Element | void => {
		const { fieldType, ...inputProps } = input
		switch (fieldType) {
			case FormFieldType.text:
				return (
					<TextInput
						{...(inputProps as TextInputProps)}
						className={getPrefixedClassName('field')}
						key={`${key}-${index}`}
						name={key}
						value={inputs[key].value || ''}
						onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
						errorMessage={inputs[key].errorMessage}
						error={inputs[key].error}
						disabled={formLocked || inputs[key].disabled}
						highlight={undefined !== inputs[key].error || FormErrorType.errorResponse === formError}
						usedInForm={true}
					/>
				)

			case FormFieldType.googlePlacesAutoComplete:
				return (
					<GooglePlacesAutocomplete
						{...(inputProps as IGooglePlacesAutocomplete)}
						className={getPrefixedClassName('field')}
						key={`${key}-${index}`}
						name={key}
						value={inputs[key].value || ''}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							;(inputProps as IGooglePlacesAutocomplete).onChange?.(e)
							onChange(e)
						}}
						errorMessage={inputs[key].errorMessage}
						error={inputs[key].error}
						disabled={formLocked || inputs[key].disabled}
						highlight={undefined !== inputs[key].error || FormErrorType.errorResponse === formError}
						usedInForm={true}
					/>
				)

			case FormFieldType.date:
				return (
					<DateInput
						{...(inputProps as DateInputProps)}
						className={getPrefixedClassName('field')}
						key={`${key}-${index}`}
						name={key}
						value={((inputs[key].value as unknown) as Date) || ''}
						onChange={(value: Date) => {
							// e does not exists so we have to create our own
							const event = ({
								target: {
									name: key,
									value,
								},
							} as unknown) as ChangeEvent<HTMLInputElement>

							onChange(event)
						}}
						error={inputs[key].error}
						disabled={formLocked || inputs[key].disabled}
					/>
				)

			case FormFieldType.select:
				return (
					<SelectInput
						{...(inputProps as SelectInputProps)}
						key={`${key}-${index}`}
						name={key}
						className={getPrefixedClassName('field')}
						returnEvent={true}
						disabled={formLocked || inputs[key].disabled}
						onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
					/>
				)

			case FormFieldType.switchSelect:
				return (
					<SwitchSelect
						name={key}
						key={`${key}-${index}`}
						{...(inputProps as SwitchSelectProps)}
						disabled={formLocked || inputs[key].disabled}
						onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
					/>
				)

			case FormFieldType.radioButtons:
				return (
					<div key={`${key}-${index}`} className="radiobutton-group">
						{inputProps.label ? <div>{inputProps.label}</div> : ''}

						{(inputProps as { options: RadiobuttonProps[] }).options.map(
							(element: RadiobuttonProps, radioIndex: number) => {
								return (
									<Radiobutton
										name={key}
										disabled={formLocked || inputs[key].disabled}
										checked={inputs[key].value === element.value}
										key={`${key}-${radioIndex}`}
										{...element}
										onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
									/>
								)
							}
						)}
					</div>
				)

			case FormFieldType.checkBox:
				return (
					<Checkbox
						name={key}
						key={`${key}-${index}`}
						{...(inputProps as CheckboxProps)}
						disabled={formLocked || inputs[key].disabled}
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							;(inputProps as CheckboxProps).onChange?.(e)
							onChange(e)
						}}
					/>
				)
			// case FormFieldType.textArea:
			// 	return (
			// 		<></>
			// 		// <TextArea
			// 		// 	name={key}
			// 		// 	value={inputProps.value}
			// 		// 	disabled={formLocked || inputs[key].disabled}
			// 		// 	onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e)}
			// 		// />
			// 	)
			// case FormFieldType.checkBoxes:
			// return (
			// 	<div key={`${key}-${index}`} className="checkbox-group">
			// 		{inputProps.label ? <div>{inputProps.label}</div> : ''}

			// 		{(inputProps as { options: CheckboxProps[] }).options.map(
			// 			(element: CheckboxProps, checkBoxIndex: number) => {
			// 				return (
			// 					<Checkbox
			// 						key={`${key}-${checkBoxIndex}`}
			// 						name={key}
			// 						checked={
			// 							undefined !== inputs[key].value &&
			// 							(inputs[key].value as string[]).includes(String(element.value))
			// 						}
			// 						value={element.value}
			// 						label={element.label}
			// 						onChange={(e: ChangeEvent<HTMLInputElement>) => onCheckboxChange(e, key)}
			// 					/>
			// 				)
			// 			}
			// 		)}
			// 	</div>
			// )
		}
	}

	/**
	 * submit the login form on hitting enter
	 * @param event
	 */
	const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
		if ('Enter' !== event.key || FormErrorType.none !== formError || true === props.disabled) {
			return
		}

		event.preventDefault()

		onHandleSubmit()
	}

	const onHandleSubmit = () => {
		if (true === formLocked) {
			return
		}

		handleSubmit()
	}

	const onHandleFormUnlock = async () => {
		let updatedFormLocked = false

		if (props.onFormUnlock) {
			updatedFormLocked = await props.onFormUnlock()
		}

		setFormLocked(updatedFormLocked)
	}

	const onHandleCaptcha = (token: string | null) => {
		const updateEvent = {
			target: {
				name: 'captchaResponseToken',
				value: token,
			},
		}
		handleInputChange(updateEvent as ChangeEvent<HTMLInputElement>)
	}

	return (
		<form
			ref={form}
			className={[
				getPrefixedClassName('form', true, true),
				props.hideSubmitIfFieldsEmpty ? 'form--hide-submit-if-fields-empty' : null,
			].join(' ')}
			onKeyDown={onKeyDown}
		>
			<div className={getPrefixedClassName('fields')}>
				{Object.keys(props.fields).map((key: string, index: number) => {
					return renderInput(key, props.fields[key], index)
				})}

				{props.useCaptcha && <Captcha onChange={onHandleCaptcha} />}
			</div>

			{props.children}

			{true !== props.hideSubmit && (
				<Button
					className={getPrefixedClassName('submit', false, true)}
					onClick={onHandleSubmit}
					label={props.submitLabel || t('generic.send')}
					type={ButtonType.primary}
					disabled={FormErrorType.none !== formError || true === props.disabled}
				/>
			)}

			{true === props.formLocked && props.formUnlockable ? (
				<Button
					className={getPrefixedClassName('unlock')}
					onClick={onHandleFormUnlock}
					label={props.unlockFormLabel || t('generic.edit')}
					type={props.unlockFormType || ButtonType.secondary}
				/>
			) : (
				''
			)}

			<ErrorMessage
				className={getPrefixedClassName('error')}
				visible={FormErrorType.errorResponse === formError}
				messages={props.errorMessages || [t('generic.formErrors.generic')]}
				errorIcon={props.errorIcon}
			/>
		</form>
	)
})

export default Form
