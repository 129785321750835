import { A, useQueryParams } from 'hookrouter'
import { Challenge, useAuth } from 'hooks/useAuth'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import routesDictionary from 'routes'
import Form, { FormFields, FormFieldType } from 'shared/components/Form'
import { TextInputType } from 'shared/components/TextInput'
import Tooltip from 'shared/components/Tooltip'
import { ApiErrorCode } from 'shared/enums'
import isDev from 'shared/helper/isDev'
import { useRouteHelper } from 'shared/hooks/useRouteHelper'

const ProvidePin = () => {
	const { getMainPath, navigateTo } = useRouteHelper()
	const [queryParams] = useQueryParams()
	const { t } = useTranslation()
	const auth = useAuth()
	const [errorMessages, setErrorMessages] = useState<string[]>()

	const inputElements: FormFields = {
		pin: {
			value: isDev()
				? queryParams.initialPin
					? (process.env.REACT_APP_INITIAL_PIN as string)
					: (process.env.REACT_APP_PIN as string)
				: '',
			required: true,
			type: TextInputType.password,
			label: queryParams.initialPin
				? t('view.login.formFieldLabels.initialPin')
				: t('view.login.formFieldLabels.pin'),
			hidePasswordLabel: queryParams.initialPin
				? t('view.login.formFieldLabels.hideInitialPin')
				: t('view.login.formFieldLabels.hidePin'),
			showPasswordLabel: queryParams.initialPin
				? t('view.login.formFieldLabels.showInitialPin')
				: t('view.login.formFieldLabels.showPin'),
			autoComplete: queryParams.initialPin ? 'none' : 'current-password',
			fieldType: FormFieldType.text,
		},
	}

	return (
		<div className="form-view">
			<div className="form-view__title">
				<h1>
					{queryParams.initialPin ? (
						<Trans i18nKey="view.login.pageTitle.provideInitialPin" />
					) : (
						<Trans i18nKey="view.login.pageTitle.providePin" />
					)}
				</h1>
				{queryParams.initialPin && (
					<>
						<p>
							<Trans i18nKey="view.login.bodytext.provideInitialPin" />
						</p>
						<div>
							{t('view.login.letterHelp.header')}{' '}
							<Tooltip
								headline={t('view.login.letterHelp.header')}
								content={
									<Trans i18nKey="generic.helpHotline.content">
										<a href={t('generic.helpHotline.telLink')}>{'  '}</a>
										<a href={t('generic.helpHotline.mailLink')}>{'  '}</a>
									</Trans>
								}
							/>
						</div>
					</>
				)}
			</div>
			<Form
				className="form-view"
				fields={inputElements}
				onSubmit={auth.sendChallengeAnswer}
				onError={(errorResponse: Challenge) => {
					if (ApiErrorCode.ACCOUNT_TEMPORARY_LOCKED === errorResponse.lastChallengeError) {
						// FIXME: timeout needed, see https://github.com/Paratron/hookrouter/issues/110
						setTimeout(() => {
							navigateTo(getMainPath(routesDictionary.accountLocked))
						}, 0)
						return
					}

					const errorMessageArray = [
						t(`apiErrors.${errorResponse.lastChallengeError}`, { error: errorResponse.lastChallengeError }),
					]

					if (ApiErrorCode.USERNAME_OR_PASSWORD_INCORRECT === errorResponse.lastChallengeError) {
						errorMessageArray.push(
							t('view.login.formErrors.remainingTries', {
								remainingTries: errorResponse.remainingTries,
							})
						)
					}

					setErrorMessages(errorMessageArray)
				}}
				errorMessages={errorMessages}
				submitLabel={t('view.login.buttonLabel.provideInitialPin')}
			>
				{!queryParams.initialPin && undefined !== getMainPath(routesDictionary.resetPin) && (
					<div className="form-view__additional-fields">
						<A href={getMainPath(routesDictionary.resetPin)!}>{t('view.login.buttonLabel.resetPin')}</A>
					</div>
				)}
			</Form>
		</div>
	)
}

export default ProvidePin
